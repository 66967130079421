import React from "react";
import Chart from "react-apexcharts";
import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import "./index.css";
import Card from "@mui/material/Card";

const PostAndVoteByTopUser = () => {
  const allUserReports = useSelector(
    (state) => state.totalUserReport.allUserReport
  );
  const userPostsReports = useSelector(
    (state) => state.totalUserReport.userPostsReport
  );
  const userPostsAndVoteReports = useSelector(
    (state) => state.totalUserReport.userPostsAndVoteReport
  );
  const userPostAndVoteByDate = userPostsAndVoteReports.numberofVote;

  const votePostByDate = [];

  if (userPostAndVoteByDate != undefined) {
    votePostByDate.push(userPostAndVoteByDate.numberofPosts);
    votePostByDate.push(userPostAndVoteByDate.numberofVotes);
    votePostByDate.push(userPostAndVoteByDate.numberofComments);
  }

  const reportValues = Object.values(userPostsReports);
  const seriesNewData = [];

  if (reportValues.length > 0) {
    seriesNewData.push(reportValues[0].averagecomment);
    seriesNewData.push(reportValues[0].averagepost);
    seriesNewData.push(reportValues[0].averagevote);
  }

  const newSeries = [{ data: seriesNewData }];

  const reportValue = allUserReports.result;
  const seriesData = [];
  const labelsData = [];
  reportValue &&
    reportValue.map((val) => {
      seriesData.push(val.postcount);
      labelsData.push(val.username);
    });
  const series = [{ data: seriesData, name: "Posts" }];
  const options = {
    labels: labelsData,
    plotOptions: {
      bar: {
        barHeight: "100%",
        distributed: true,
        dataLabels: {
          position: "center",
        },
      },
    },
    colors: ["#3522E6", "#800080", "#FF7300", "#FF0000", "#007ED6"],
  };
  const VoteValue = allUserReports.result;
  const seriesVoteData = [];
  const labelsVoteData = [];
  VoteValue &&
    VoteValue.map((val) => {
      seriesVoteData.push(val.totalvote);
      labelsVoteData.push(val.username);
    });

  const voteSeries = [{ data: seriesVoteData, name: "Votes" }];

  const voteOptions = {
    labels: labelsVoteData,
    plotOptions: {
      bar: {
        barHeight: "100%",
        distributed: true,
        dataLabels: {
          position: "center",
        },
      },
    },
    colors: ["#FF0000", "#007ED6"],
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
          <Card sx={{ m: 2, boxShadow: 2 }} style={{ width: "95%" }}>
            <p className="top-user-heading">Top users by post</p>
            <Chart type="bar" series={series} options={options}></Chart>
          </Card>
        </Grid>

        <Grid item md={6} xs={12}>
          <Card sx={{ m: 2, boxShadow: 2 }} style={{ width: "95%" }}>
            <p className="top-user-heading">Top users by vote</p>
            <Chart
              type="area"
              series={voteSeries}
              options={voteOptions}
            ></Chart>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default PostAndVoteByTopUser;
