import React from "react";
import { InputLabel, MenuItem, FormControl, Select, Grid } from "@mui/material";
export const DataFilterByPortal = ({ portal, setPortal }) => {
  const handleChangePortal = (event) => {
    setPortal(event.target.value);
  };
  return (
    <Grid container justifyContent="flex-end">
     <Grid item xs={6} md={3} sx={{ marginTop: "35px" }}>
        <FormControl sx={{ width: 180 }}>
          <InputLabel id="demo-simple-select-label">Portal</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={portal}
            label="portal"
            onChange={handleChangePortal}
          >
            <MenuItem value={"All"}>All</MenuItem>
            <MenuItem value={"junkee"}>Junkee</MenuItem>
            <MenuItem value={"wizer"}>Wizer</MenuItem>
            <MenuItem value={"winepilot"}>Winepilot</MenuItem>
            <MenuItem value={"techdiversity"}>Techdiversity</MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};
