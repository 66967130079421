import React from "react";
import { Redirect, Route } from "react-router-dom";

// import { getUserRole } from "../actions/userData";

const LoginRoute = ({ component: Component, ...rest }) => {
  const loadComponent = (props) => {
    const isLogin = localStorage.getItem("authenticated");
    const role = localStorage.getItem("userRole");
    return isLogin ? (
      role == "admin" ? (
        <Redirect to="/dashboard" />
      ) : (
        <Redirect to="/superadmin/dashboard" />
      )
    ) : (
      <Component {...props} />
    );
  };

  return <Route {...rest} render={(props) => loadComponent(props)} />;
};

export default LoginRoute;
