import {
  ALL_USER_DATA_SUCCESS,
  ALL_USER_DATA_EXPORT_SUCCESS,
  ALL_USER_DETAIL_SUCCESS,
  USER_ROLE_SUCCESS,
  ALL_USER_DATA_SEARCH_SUCCESS,
  VOTEBY_PORTALTYPE_SUCCESS,
  VOTEBY_PORTALTYPE_COUNT_SUCCESS,
  ALL_VOTE_COUNT_BY_AGE_RANGE_SUCCESS,
  ALL_VOTE_COUNT_BY_TIME_SUCCESS,
  ALL_VOTE_BY_ETHNICITY_SUCCESS,
  USER_BY_VOTE_SUCCESS,
  TOP_CATEGORIES_BYVOTE_SUCCESS,
  QUESTION_VOTECOUNT_BY_AGE_SUCCESS,
  QUESTION_VOTECOUNT_BY_EHNICITY_SUCCESS,
  CREATE_POST_TAG_SUCCESS,
  GET_POST_FOR_TAG_SUCCESS,
  GET_POST_AND_TAG_DATA_SUCCESS,
  GET_SEARCH_POST_FOR_TAG_SUCCESS,
  GET_COUNT_FOR_TAG_SUCCESS,
  GET_SEARCH_RESULT_FOR_TAG_SUCCESS,
  GET_CLOSED_POST_COUNT_SUCCESS,
} from "../constants";

export function userData(
  state = {
    isFetching: false,
    usersData: {},
    usersDataSearch: {},
    errorMessage: "",
    userDetail: {},
    usersDataExport: [],
    userRole: "",
    userVotesByPortalType: {},
    votesByPortalTypeCount: {},
    voteByAgeRange: {},
    voteCountByHours: [],
    voteByEthnicity: {},
    userByVote: [],
    topCategoryByVote: [],
    QuestionVoteByAge: {},
    QuestionVoteByEthnicity: {},
    sucessMessage: "",
    getLiveQuestion: "",
    getPostAndTagQuestion: "",
    getSearchData: "",
    tagPostCount: "",
    getTagSearchData: "",
    getClosedCount:""
    // isAuthenticated: authenticated,
  },
  action
) {
  switch (action.type) {
    case ALL_USER_DATA_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        usersData: action.payload,
        errorMessage: "",
      });
    case ALL_USER_DATA_SEARCH_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        usersDataSearch: action.payload,
        errorMessage: "",
      });
    case ALL_USER_DETAIL_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        userDetail: action.payload,
        errorMessage: "",
      });
    case ALL_USER_DATA_EXPORT_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        usersDataExport: action.payload,
        errorMessage: "",
      });
    case USER_ROLE_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        userRole: action.payload,
        errorMessage: "",
      });

    case VOTEBY_PORTALTYPE_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        userVotesByPortalType: action.payload,
        errorMessage: "",
      });
    case VOTEBY_PORTALTYPE_COUNT_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        votesByPortalTypeCount: action.payload,
        errorMessage: "",
      });

    case ALL_VOTE_COUNT_BY_AGE_RANGE_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        voteByAgeRange: action.payload,
        errorMessage: "",
      });

    case ALL_VOTE_COUNT_BY_TIME_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        voteCountByHours: action.payload,
        errorMessage: "",
      });
    case ALL_VOTE_BY_ETHNICITY_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        voteByEthnicity: action.payload,
        errorMessage: "",
      });

    case USER_BY_VOTE_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        userByVote: action.payload,
        errorMessage: "",
      });
    case TOP_CATEGORIES_BYVOTE_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        topCategoryByVote: action.payload,
        errorMessage: "",
      });
    case QUESTION_VOTECOUNT_BY_AGE_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        QuestionVoteByAge: action.payload,
        errorMessage: "",
      });

    case QUESTION_VOTECOUNT_BY_EHNICITY_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        QuestionVoteByEthnicity: action.payload,
        errorMessage: "",
      });

    case CREATE_POST_TAG_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        sucessMessage: action.payload,
        errorMessage: "",
      });

    case GET_POST_FOR_TAG_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        getLiveQuestion: action.payload,
        errorMessage: "",
      });
    case GET_POST_AND_TAG_DATA_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        getPostAndTagQuestion: action.payload,
        errorMessage: "",
      });

    case GET_SEARCH_POST_FOR_TAG_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        getSearchData: action.payload,
        errorMessage: "",
      });

    case GET_COUNT_FOR_TAG_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        tagPostCount: action.payload,
        errorMessage: "",
      });

    case GET_SEARCH_RESULT_FOR_TAG_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        getTagSearchData: action.payload,
        errorMessage: "",
      });
      case GET_CLOSED_POST_COUNT_SUCCESS:
        return Object.assign({}, state, {
          isFetching: false,
          getClosedCount: action.payload,
          errorMessage: "",
        });
    default:
      return state;
  }
}
