import React, { useState } from "react";
import { useSelector } from "react-redux";
import Chart from "react-apexcharts";
import DataTable from "../../DataTable";
import { Grid } from "@mui/material";
import { DataFilterByPortal } from "../../DataFilterByPortal";

const AgeRange = ({portal,setPortal}) => {
  // const [portal, setPortal] = useState("");
  
  const allVoteByAgeRange = useSelector(
    (state) => state.userData.voteByAgeRange.voteCount
  );

  const portals = [
    { name: "wizer", color: "#800080" },
    { name: "junkee", color: "#2f8dcf" },
    { name: "winepilot", color: "#ff7300" },
    { name: "techdiversity", color: "#1a8286" },
    { name: "anonymous", color: "#00ff73" },
  ];

  const categories = [
    ...new Set(
      portals.flatMap((portal) =>
        allVoteByAgeRange
          ?.filter((item) => item.portal === portal.name)
          .map((item) => item.age_range)
      )
    ),
  ].sort();

  const options1 = {
    chart: {
      type: "area",
      height: 490,
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      title: {
        text: "Age",
        style: {
          fontSize: 18,
        },
      },
      categories: categories,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      tooltip: {
        enabled: true,
      },
    },
    yaxis: {
      title: {
        text: "Vote Count",
        style: {
          fontSize: 18,
        },
      },
      labels: {
        formatter: function (val) {
          return val;
        },
      },
    },
    tooltip: {
      x: {
        show: false,
      },
      y: {
        formatter: function (val) {
          return val + "  vote";
        },
      },
      marker: {
        show: true,
      },
    },
    legend: {
      position: "bottom",
      fontSize: "16px",
      offsetY: 0,
      labels: {
        colors: portals.map((portal) => portal.color),
      },
    },
  };

  const series1 = portals.map(({ name, color }) => ({
    name: name.charAt(0).toUpperCase() + name.slice(1),
    data: categories?.map((category) =>
      parseInt(
        allVoteByAgeRange?.find(
          (item) => item.portal === name && item.age_range === category
        )?.vote_count ?? 0
      )
    ),
    color: color,
  }));

  const allData = allVoteByAgeRange?.map(
    ({ portal, age_range, vote_count, percentage }) => ({
      portal: portal.charAt(0).toUpperCase() + portal.slice(1),
      ageRange: age_range,
      vote_count: Number(vote_count),
      percentage: percentage,
    })
  );

  const allLabels = [
    { key: "portal", label: "Portal" },
    { key: "ageRange", label: "Age Range" },
    { key: "vote_count", label: " Total Vote Count" },
    { key: "percentage", label: "Total Percentage" },
  ];

  return (
    <>
      <DataFilterByPortal portal={portal} setPortal={setPortal} />
      <Grid container alignItems="center" justifyContent="center">
        <Grid item xs={11}>
          <Chart options={options1} series={series1} type="area" height={460} />
        </Grid>
      </Grid>
      <DataTable data={allData} columns={allLabels} />
    </>
  );
};

export default AgeRange;
