import React from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
const ErrorMessage = ({ open, handleClose }) => {
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{ animation: `${open ? "shake 0.5s" : ""}` }}
      >
        <DialogTitle style={{ color: "red", fontFamily: "Montserrat" }}>
          Error
        </DialogTitle>
        <DialogContent>
          <DialogContentText style={{ fontSize: 20, fontFamily: "Montserrat" }}>
            Please select at least one question and add at least one tag.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            style={{
              backgroundColor: "#7C10A7",
              borderRadius: 5,
              margin: "auto",
              fontFamily: "Montserrat",
              fontSize: 18,
            }}
            variant="contained"
            onClick={handleClose}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ErrorMessage;
