import React, { useEffect, useState } from 'react'
import { Route, Redirect } from 'react-router-dom'

const PrivateRoute = ({ allowed, component: Component, ...rest }) => {
  const [mounted, setMounted] = useState(false)
  const [authenticated, setAuthenticated] = useState(false)

  const loadData = async () => {
    if (localStorage.getItem('accessToken')) {
      try {
        setAuthenticated(true)
      } catch (err) {
        setAuthenticated(false)
      }
    } else {
      setAuthenticated(false)
    }
    setMounted(true)
  }

  useEffect(() => {
    loadData()
  }, [])

  return (
    <Route
      exact
      {...rest}
      
      render={
        props =>
          mounted ? (
            authenticated ? ( // if authenticated load the desired compnonent
              <Component {...props} />
            ) : (
              <Redirect to="/" />
            )
          ) : (
            <></>
          )
        // eslint-disable-next-line react/jsx-curly-newline
      }
    />
  )
}

export default PrivateRoute