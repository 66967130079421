import {
  GET_ANONYMOUS_ACTION_LIST_SUCCESS,
  UPDATE_ANONYMOUSVOTE_PORTAL_STATUS_SUCCESS,
} from "../constants";

export function anonymousVoteHandler(
  state = {
    isFetching: false,
    errorMessage: "",
    updateValue: "",
    getAnonymousStatus: "",
  },
  action
) {
  switch (action.type) {
    case UPDATE_ANONYMOUSVOTE_PORTAL_STATUS_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        updateValue: action.payload,
        errorMessage: "",
      });
    case GET_ANONYMOUS_ACTION_LIST_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        getAnonymousStatus: action.payload,
        errorMessage: "",
      });

    default:
      return state;
  }
}
