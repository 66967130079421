import React from "react";
import { styled } from "@mui/material/styles";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  tableCellClasses,
} from "@mui/material";
import Loading from "../Loading";

const DataTable = ({ data, columns }) => {
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: "#F7F6FE",
      borderTop: "2px solid white",
      borderBottom: "2px solid white",
    },
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));
  const styleOnAction = columns[7]?.label == "Action";
  if (!data || data.length === 0) {
    return (
      <Grid container alignItems="center" justifyContent="center" sx={{ m: 5 }}>
        <Loading />
      </Grid>
    );
  }
  return (
    <Grid container alignItems="center" justifyContent="center">
      <Grid item xs={12} md={styleOnAction === true ? 12 : 10}>
        <TableContainer
          sx={{ boxShadow: "none !important", marginTop: 10, marginBottom: 4 }}
        >
          <Table
            sx={{
              maxWidth: styleOnAction === true ? 1600 : 1200,
              margin: "10px auto",
            }}
            aria-label="customized table"
          >
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <StyledTableCell
                    key={column.key}
                    sx={{
                      width: "15%",
                      textAlign: "center",
                      fontFamily: "Montserrat",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    {column.label}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((item) => (
                <StyledTableRow key={item.id} sx={{ width: "15%" }}>
                  {columns.map((column) => {
                    const value = item[column.key];
                    return (
                      <StyledTableCell
                        key={`${item.id}-${column.key}`}
                        component="th"
                        scope="row"
                        sx={{
                          width: "15%",
                          textAlign: "center",
                          fontFamily: "Montserrat",
                          fontSize: 14,
                          fontWeight: 500,
                        }}
                      >
                        {value}
                      </StyledTableCell>
                    );
                  })}
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default DataTable;
