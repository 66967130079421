import React, { useState, useEffect } from "react";
import "./index.css";
import CountUp from "react-countup";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { getJunkeeCount } from "../../../actions/overallStats";
const TextualData = ({ days, startDate, EndDate }) => {
  const dispatch = useDispatch();
  const userPostsAndVoteReports = useSelector(
    (state) => state.totalUserReport.userPostsAndVoteReport
  );
  const junkeeCounts = useSelector((state) => state.overallStats.junkeeCount);

  const [junkeeCount, setJunkeeCount] = useState();

  useEffect(() => {
    dispatch(getJunkeeCount());
  }, []);

  useEffect(() => {
    if (junkeeCounts != "undefined" && junkeeCounts != "")
      setJunkeeCount(junkeeCounts[0].totalexper);
  }, [junkeeCount, junkeeCounts]);
  const newRegistrations = useSelector(
    (state) => state.totalUserReport.newRegistration
  );
  const series = [];
  {
    newRegistrations.length > 0 &&
      newRegistrations.map((item) => {
        if (item.count != undefined && item.count != "") {
          series.push(item.count);
        }
      });
  }
  const data = series[0];
  const userPostAndVoteByDate = userPostsAndVoteReports.result;
  // const userPostAndVoteByDate =
  // userPostsAndVoteReports && userPostsAndVoteReports.numberofVote;

  const votePostByDate = [];
  if (userPostAndVoteByDate != undefined) {
    votePostByDate.push(userPostAndVoteByDate.numberofPosts);
    votePostByDate.push(userPostAndVoteByDate.numberofVotes);
    votePostByDate.push(userPostAndVoteByDate.numberofComments);
  }
  const totalPost =
    votePostByDate.length > 0 ? (
      <CountUp duration={1} end={votePostByDate[0]} />
    ) : (
      <div className="loading-title"> Loading...</div>
    );
  const totalVote =
    votePostByDate.length > 0 ? (
      <CountUp duration={1} end={votePostByDate[1]} />
    ) : (
      <div className="loading-title"> Loading...</div>
    );
  const totalComment =
    votePostByDate.length > 0 ? (
      <CountUp duration={1} end={votePostByDate[2]} />
    ) : (
      <div className="loading-title"> Loading...</div>
    );

  const newRegistered =
    votePostByDate.length > 0 ? (
      <CountUp duration={1} end={data != undefined ? data : junkeeCount} />
    ) : (
      <div className="loading-title"> Loading...</div>
    );

  const daysCounter =
    days != null && data != undefined ? (
      <>
        <div className="textual-heading-text">New Registered</div>
        {days != 0 ? (
          <div className="total-days">
            {moment(startDate).format(" Do MMMM, YYYY ")}
            <i>
              {" "}
              <strong> -- </strong>
            </i>{" "}
            {moment(EndDate).format(" Do MMMM , YYYY ")}
          </div>
        ) : (
          <div className="total-days">Today </div>
        )}
      </>
    ) : (
      <div className="textual-heading-text"> Total user</div>
    );
  return (
    <>
      <div md={12} className="box-container">
        <div className="box1">
          <div className="textual-heading-data"> {totalPost}</div>
          <div className="textual-heading-text">Total Posts</div>
        </div>
        <div className="box2">
          <div className="textual-heading-data"> {totalVote}</div>
          <div className="textual-heading-text">Total Votes</div>
        </div>
        <div className="box3">
          <div className="textual-heading-data"> {totalComment}</div>
          <div className="textual-heading-Total-text">Total Comments</div>
        </div>
        <div className="box4">
          <div className="textual-heading-data">{newRegistered}</div>
          {daysCounter}
        </div>
      </div>
    </>
  );
};
export default TextualData;
