import React from "react";
import { Switch, Route } from "react-router-dom";
import LoginRoute from "./LoginRoute";
import PrivateRoute from "./PrivateRoute";
import Dashboard from "../pages/Dashboard";
import UserDetails from "../pages/UserDetails/index";
import SignIn from "../pages/SignIn/index";
import SuperAdminDashboard from "../pages/SuperAdminDashboard";


const RouterHandle = () => {
  // Get the user role from localStorage
  const userRole = localStorage.getItem("userRole");

  return (
    <Switch>
      <LoginRoute exact path="/" component={SignIn} />
      <PrivateRoute
        path="/dashboard"
        component={Dashboard}
        
        allowed={['admin']}
        userRole={userRole}
      />
      <PrivateRoute
        path="/superadmin/dashboard"
        component={SuperAdminDashboard}
        allowed={['superadmin']}
        userRole={userRole}
      /> 
      
      <PrivateRoute
        path="/userdetails/:id"
        exact
        component={UserDetails}
        allowed={['admin', 'superadmin']}
        userRole={userRole}
      />
      <Route path="/SignIn" exact component={SignIn} />
    </Switch>
  );
};

export default RouterHandle;
