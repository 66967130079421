import React, { useCallback, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import DataTable from "../DataTable";
import { Button, Grid, Pagination, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserDataSearch,
  getUserData,
  userBan,
  userUnBan,
} from "../../../actions/userData";
import "./index.css";
import ExportAllUsersData from "../ExportAllUserData";
import Loading from "../Loading";
import DefaultImage from "../../../assets/icons/defaultImage.svg";

const UsersDetailList = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const usersList = useSelector((state) => state.userData.usersData);
  const expertCounts = useSelector((state) => state.overallStats.junkeeCount);
  const usersDataSearch = useSelector(
    (state) => state.userData.usersDataSearch
  );
  const [usersData, setUsersData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalUserCount, setTotalUserCount] = useState(0);
  const [searched, setSearched] = useState("");

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect(() => {
    if (expertCounts?.length > 0) {
      setTotalUserCount(expertCounts[0].totalexper);
    }
  }, [expertCounts]);

  useEffect(() => {
    if (usersDataSearch.length > 0) {
      setUsersData(usersDataSearch);
    } else {
      setUsersData(usersList);
    }
  }, [usersList, usersDataSearch]);

  const fetchData = useCallback(async () => {
    dispatch(getUserData("", currentPage));
  }, [dispatch, currentPage]);

  const userDetailRedirect = (id) => {
    history.push(`/userdetails/${id}`);
    window.location.reload();
  };

  const banClick = async (value) => {
    await dispatch(userBan(value));
    window.location.reload();
  };

  const unbanClick = async (value) => {
    await dispatch(userUnBan(value));
    window.location.reload();
  };

  const requestSearch = async (event) => {
    const searchTerm = event.target.value;
    setSearched(searchTerm);

    if (searchTerm.length >= 3 || searchTerm.length === 0) {
      await dispatch(
        getUserDataSearch(searchTerm.length <= 3 ? [] : searchTerm)
      );
      setUsersData(usersDataSearch);
    } else {
      setUsersData(usersList);
    }
  };
  const cancelSearch = () => {
    setSearched("");
    requestSearch({ target: { value: "" } });
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const allLabels = [
    { key: "username", label: "Username" },
    { key: "gender", label: "Gender" },
    { key: "age", label: "Age" },
    { key: "location", label: "Location" },
    { key: "usertype", label: "User type" },
    { key: "email", label: "E-mail" },
    { key: "view", label: "View" },
    { key: "action", label: "Action" },
  ];
  const allData =
    usersData &&
    usersData.length > 0 &&
    usersData?.map((user) => ({
      id: user.id,
      username: (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <img
            src={
              user.image == null
                ? DefaultImage
                : user.image.includes("googleusercontent")
                ? user.image
                : `${process.env.REACT_APP_IMAGE_URL}` + user.image
            }
            alt="user"
            width={40}
            height={40}
            style={{
              borderRadius: "8px",
              marginRight: "8px",
              objectFit: "cover",
            }}
          />
          <span>{user.username}</span>
        </div>
      ),
      gender: user.gender,
      age: user.age,
      location: user.location,
      usertype: user.portalType,
      email: user.email,
      view: (
        <Button
          style={{
            display: "flex",
            backgroundColor: "#7C10A7",
            borderRadius: 5,
            height: 30,
            width: "75px",
            color: "White",
          }}
          variant="contained"
          onClick={() => userDetailRedirect(user.id)}
        >
          Details
        </Button>
      ),
      action: user.isBanned ? (
        <Button
          style={{
            display: "flex",
            backgroundColor: "#7C10A7",
            borderRadius: 5,
            width: "75%",
          }}
          variant="contained"
          className="unBan_btn"
          onClick={() => unbanClick(user.id)}
        >
          <p>UnBan</p>
        </Button>
      ) : (
        <Button
          style={{
            display: "flex",
            backgroundColor: "#7C10A7",
            borderRadius: 5,
            width: "75%",
          }}
          variant="contained"
          className="ban_btn"
          onClick={() => banClick(user.id)}
        >
          <p> Ban</p>
        </Button>
      ),
    }));

  return (
    <Grid container alignItems="center" justifyContent="center">
      {usersData.length === 0 ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "53vh",
          }}
        >
          <Loading />
        </div>
      ) : (
        <>
          <Grid
            item
            xs={12}
            md={12}
            container
            style={{
              justifyContent: "flex-end",
              position: "relative",
              top: 70,
            }}
          >
            <div className="search-button">
              <TextField
                value={searched}
                placeholder="Search user details by username"
                variant="outlined"
                onChange={requestSearch}
                style={{ width: "100%" }}
              />
            </div>
          </Grid>
          <DataTable data={allData} columns={allLabels} />

          <Pagination
            count={Math.ceil(totalUserCount / 20)}
            page={currentPage}
            onChange={handlePageChange}
            color="secondary"
            shape="rounded"
            style={{ margin: "auto" }}
          />
          <ExportAllUsersData />
        </>
      )}
    </Grid>
  );
};

export default UsersDetailList;
