import React, { useState, useEffect, useRef } from "react";
import { Grid, Pagination } from "@mui/material";
import DefaultImage from "../../../../assets/icons/defaultImage.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserVoteDataByPortalType,
  getVoteDataByPortalTypeCount,
} from "../../../../actions/userData";
import DataTable from "../../DataTable";

export const Portal = () => {
  const userVotesByPortalTypes = useSelector(
    (state) => state.userData.userVotesByPortalType
  );
  const totalVotesByPortalTypeCount = useSelector(
    (state) => state.userData.votesByPortalTypeCount
  );
  const dispatch = useDispatch();
  const limit = useRef(20);
  const [votePage, setVotePage] = useState(1);
  const [voteCount, setVoteCount] = useState(0);
  const role = localStorage.getItem("userRole");
  const portalType = localStorage.getItem("portalType");
  const name = localStorage.getItem("name");

  useEffect(() => {
    dispatch(
      getUserVoteDataByPortalType(portalType, votePage, limit.current, name)
    );
    dispatch(getVoteDataByPortalTypeCount(portalType, name));
  }, [dispatch, role, portalType, votePage]);

  useEffect(() => {
    if (
      totalVotesByPortalTypeCount.length > 0 &&
      totalVotesByPortalTypeCount[0] !== undefined
    ) {
      setVoteCount(totalVotesByPortalTypeCount[0].totalpost);
    }
  }, [totalVotesByPortalTypeCount]);

  const handleChange = (event, value) => {
    setVotePage(value);
  };

  const voteTotalCount = Math.ceil(Number(voteCount) / limit.current) || 0;
  const tableColumns1 = [
    { key: "username", label: "User who post" },
    { key: "question", label: "Post" },
    { key: "answer", label: "Decided Answer" },
    { key: "voteCounts", label: "Vote (By Portal type)" },
    { key: "totalVotes", label: "Total" },
  ];

  const matchingPortalType = portalType;
  const anonymousPortalType = "anonymous";
  const tableData1 = userVotesByPortalTypes.postbyUserportal?.map((row, id) => {
    const filteredVotes = row[1].filter(
      (item) =>
        item.portal_type === matchingPortalType ||
        item.portal_type === anonymousPortalType ||
        (item.portal_type !== matchingPortalType && item.portal_type !== anonymousPortalType)
    );
  
    const matchingPortalVotes = filteredVotes.find(
      (item) => item.portal_type === matchingPortalType
    );

    const anonymousPortalVotes = filteredVotes.find(
      (item) => item.portal_type === anonymousPortalType
    );
  
    const otherPortalVotes = filteredVotes.filter(
      (item) => item.portal_type !== matchingPortalType && item.portal_type !== anonymousPortalType
    );
  
    const communityVotes = otherPortalVotes.reduce(
      (acc, item) => {
        acc.total += parseInt(item.votecount);
        return acc;
      },
      { total: 0 }
    );
  
    return {
      id: id,
      username: (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginLeft: 67,
          }}
        >
          <img
            src={
              row[0].image == null
                ? DefaultImage
                : row[0].image.includes("googleusercontent")
                ? row[0].image
                : `${process.env.REACT_APP_IMAGE_URL}${row[0].image}`
            }
            width={40}
            height={40}
            style={{ borderRadius: "8px", marginRight: "8px" }}
            alt="User Avatar"
          />
          <div>{row[0].username}</div>
        </div>
      ),
      question: row[0].question,
      answer: row[0].win_answer === "N/A" ? "Undecided" : row[0].win_answer,
      voteCounts: (
        <>
          {matchingPortalVotes && (
            <div>
              {matchingPortalType.charAt(0).toUpperCase() +
                matchingPortalType.slice(1)}
              : {matchingPortalVotes.votecount}
            </div>
          )}
  
          {communityVotes.total !== 0 && (
            <div>
              Community: {communityVotes.total}
            </div>
          )}
  
          {filteredVotes.every(
            (item) =>
              item.portal_type === anonymousPortalType && item.votecount === "0"
          ) && <div>No vote</div>}
  
          {anonymousPortalVotes && anonymousPortalVotes.votecount !== "0" && (
            <div>
              {anonymousPortalType.charAt(0).toUpperCase() +
                anonymousPortalType.slice(1)}
              : {anonymousPortalVotes.votecount}
            </div>
          )}
        </>
      ),
      totalVotes: filteredVotes.reduce(
        (acc, item) => {
          acc.total += parseInt(item.votecount);
          return acc;
        },
        { total: 0 }
      ).total,
    };
  });
  
  return (
    <>
      <Grid style={{ justifyContent: "flex-end", margin: "30px auto" }}></Grid>
      <DataTable data={tableData1} columns={tableColumns1} />
      <Pagination
        count={voteTotalCount}
        page={votePage}
        onChange={handleChange}
        color="secondary"
        shape="rounded"
        style={{ margin: "auto" }}
      />
    </>
  );
};
