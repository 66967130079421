import { get } from "../api/axios";
import {
  CATEGORIES_DATA_SUCCESS,
  CATEGORIES_DATA_ERROR,
  ALL_CATEGORY_LEADERBOARD_FAILURE,
} from "../constants";
import { ALL_CATEGORY_LEADERBOARD_SUCCESS } from "../constants/index";

export function getCategoriesDataSuccess(payload) {
  return {
    type: CATEGORIES_DATA_SUCCESS,
    payload,
  };
}

export function getCategoriesDataFailure(payload) {
  return {
    type: CATEGORIES_DATA_ERROR,
    payload,
  };
}

export function getCategoriesLeaderboardDataSuccess(payload) {
  return {
    type: ALL_CATEGORY_LEADERBOARD_SUCCESS,
    payload,
  };
}

export function getCategoriesLeaderboardDataFailure(payload) {
  return {
    type: ALL_CATEGORY_LEADERBOARD_FAILURE,
    payload,
  };
}

export function getCategoriesData(type) {
  return async (dispatch) => {
    const response = await get(`dashboard/junkee/categories`, {
      params: { portal: type },
    });
    if (response && response.status === 200 && response.data) {
      dispatch(getCategoriesDataSuccess(response.data));
    } else {
      dispatch(getCategoriesDataFailure("Please Try again"));
    }
  };
}

export function getCategorieLeaderboardData() {
  return async (dispatch) => {
    const response = await get(`dashboard/category/leaderboard`);
    if (response && response.status === 200 && response.data) {
      dispatch(getCategoriesLeaderboardDataSuccess(response.data));
    } else {
      dispatch(getCategoriesLeaderboardDataFailure("Please Try again"));
    }
  };
}
