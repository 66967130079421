import React, { useState, useEffect } from "react";
import "./index.css";
import Pagination from "@mui/material/Pagination";
import {  useSelector } from "react-redux";
import DataTable from "../DataTable";

 const CategoryStats = () => {
  const categoryLeaderboardData = useSelector(
    (state) => state.categoriesData.categoryLeaderboardData
  );
  const [stack, setstack] = useState([]);
  const [page, setPage] = useState(1);
  const firstIndex = 0;
  const [pageSize, setPageSize] = useState(20);
  const categoryData = categoryLeaderboardData.totalPostOnCategory;

  const handleChange = (event, value) => {
    setstack(
      categoryData.slice(firstIndex + pageSize * (value - 1), pageSize * value)
    );
    setPage(value);
  };

  useEffect(() => {
    if (stack?.length == 0 && categoryData != undefined) {
      setstack(categoryData?.slice(firstIndex, pageSize));
    }
  }, [stack, categoryData]);
  const counts = Math.ceil(
    categoryData?.length > 0 && categoryData.length / pageSize
  );
  const CategoryStatsHeading = [
    { key: "category", label: "Category" },
    { key: "totalPosts", label: "# Total Posts" }, // new header
    { key: "topUsers", label: "Top Users ~ # Posts" }, // new header
  ];
  const categoryStatsData = stack.map((row) => ({
    id: row.id,
    category: row.title,
    totalPosts: row.totalpost ? row.totalpost : 0,
    topUsers: categoryLeaderboardData?.categoryArr?.map((item, index) => {
      return (
        <div key={index}>
          {item.map((itemu, index2) => {
            if (itemu.categoryid == row.category) {
              return (
                <div key={index2}>
                  {itemu.username} {" ~ "}
                  {itemu.postcount}
                </div>
              );
            }
          })}
        </div>
      );
    }),
  }));
  return (
    <>
      <DataTable data={categoryStatsData} columns={CategoryStatsHeading} />
      <Pagination
        count={counts}
        page={page}
        onChange={handleChange}
        color="secondary"
        shape="rounded"
        style={{margin:"auto"}}
      />
    </>
  );
};
export default CategoryStats