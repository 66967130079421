import React, { useState, useEffect, useRef, useCallback } from "react";
import "./index.css";
import { PostTable } from "./PostTable.jsx";
import {
  Grid,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import {
  getClosedPostCount,
  getPostForTag,
} from "../../../actions/userData";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
const SelectPostForWebApp = () => {
  const getLiveQuestions = useSelector(
    (state) => state.userData?.getLiveQuestion
  );
  
  const [votePage, setVotePage] = useState(1);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const [voteCount, setVoteCount] = useState();
  const [showPostListing, setShowPostListing] = useState(false);
  const [selectedQuestions, setSelectedQuestions] = useState("");
  const [tags, setTags] = useState([]);
  const [newTag, setNewTag] = useState("");
  const dispatch = useDispatch();
  const portalType = useSelector((state) => localStorage.getItem("portalType"));
  const role = useSelector((state) => localStorage.getItem("userRole"));
  const limit = useRef(20);
  const postClosed = false;

  const getClosedCounts = useSelector(
    (state) => state.userData.getClosedCount[0]?.totalpost
  );
  const username = localStorage.getItem("name");

  const handleTagAdd = useCallback(() => {
    if (newTag.trim() !== "") {
      const newTags = newTag
        .trim()
        .split(",")
        .map((tag) => tag.trim());
      const existingTags = tags.map((tag) => tag.trim());
      const invalidTags = newTags.filter(
        (tag) => tag === "" || existingTags.includes(tag)
      );
      const validTags = newTags.filter(
        (tag) => tag !== "" && !existingTags.includes(tag)
      );
      
      if (invalidTags.length > 0) {
        setNewTag("");
        return;
      }
      if (validTags.length > 0) {
        if (selectedQuestions.length > 0) {
          const questionsWithTag = getLiveQuestions?.filter((question) =>
            question.tag.some((tag) => validTags.includes(tag.label))
          );
          const invalidQuestions = questionsWithTag.filter(
            (question) => selectedQuestions.includes(question.postid)
          );
          if (invalidQuestions.length > 0) {
            setIsDialogOpen(true);
            return;
          }
        }
        setTags((tags) => [...tags, ...validTags]);
        setNewTag("");
      }
    }
  }, [newTag, tags, selectedQuestions, getLiveQuestions]);
  const handleChange = useCallback(
    (event, value) => {
      if (value !== votePage) {
        dispatch(
          getPostForTag(portalType, limit.current, value, postClosed, username)
        );
      }
      setVotePage(value);
    },
    [dispatch, portalType, votePage]
  );

  const handleTagInputChange = useCallback((event) => {
    setNewTag(event.target.value);
  }, []);

  const handleTagRemove = useCallback((index) => {
    setTags((tags) => tags.filter((_, i) => i !== index));
  }, []);

  useEffect(() => {
    dispatch(
      getPostForTag(
        role === "admin" ? portalType : "",
        limit.current,
        votePage,
        postClosed,
        username
      )
    );
    dispatch(
      getClosedPostCount(role === "admin" ? portalType : "", false, username)
    );
  }, [dispatch, portalType, role]);

  useEffect(() => {
    if (getClosedCounts !== undefined) {
      setVoteCount(getClosedCounts);
    }
  }, [getClosedCounts]);

  const wayToMappingSection = useCallback(() => {
    setShowPostListing(true);
  }, []);
  const voteTotalCount = voteCount
    ? Math.ceil(Number(voteCount) / limit.current)
    : 0;

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  return (
    <>
      <Dialog
        className="scale-in-out"
        open={isDialogOpen}
        onClose={handleDialogClose}
      >
        <DialogTitle
          style={{
            textAlign: "center",
            margin: "auto",
            fontSize: 24,
            fontFamily: "Montserrat",
            color: "black",
            paddingTop: 25,
            fontWeight: 700,
          }}
        >
          Oops !
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            style={{
              textAlign: "center",
              margin: "auto",
              fontSize: 21,
              fontFamily: "Montserrat",
              color: "black",
            }}
          >
            Tag already exist in post
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            style={{
              backgroundColor: "#7C10A7",
              borderRadius: 5,
              margin: "auto",
              fontFamily: "Montserrat",
              fontSize: 18,
            }}
            variant="contained"
            onClick={handleDialogClose}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>

      {!showPostListing && (
        <Grid container justifyContent="center">
          <Grid item xs={12} md={8}>
            <Box style={{ margin: "auto" }}>
              <PostTable
                getLiveQuestions={getLiveQuestions}
                selectedQuestions={selectedQuestions}
                setSelectedQuestions={setSelectedQuestions}
                newTag={newTag}
                tags={tags}
                handleTagAdd={handleTagAdd}
                handleTagRemove={handleTagRemove}
                handleTagInputChange={handleTagInputChange}
                voteTotalCount={voteTotalCount}
                handleChange={handleChange}
                voteCount={voteCount}
                wayToMappingSection={wayToMappingSection}
                setTags={setTags}
                limit={limit.current}
                votePage={votePage}
                postClosed={postClosed}
                setIsDialogOpen={setIsDialogOpen}
                isDialogOpen={isDialogOpen}
              />
            </Box>
          </Grid>
        </Grid>
      )}
      {/* <ErrorMessage open={open} handleClose={handleClose} /> */}

      <ToastContainer
        position="top-center"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};

export default SelectPostForWebApp;
