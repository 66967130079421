import { get, patch } from "../api/axios";
import {
  TOTAL_USER_POSTS_REPORT_FAILURE,
  TOTAL_USER_POSTS_REPORT_SUCCESS,
  TOTAL_USER_REPORT_FAILURE,
  TOTAL_USER_REPORT_SUCCESS,
  TOTAL_USER_POSTS_VOTES_DETAILS_DATE_SUCCESS,
  TOTAL_USER_POSTS_VOTES_DETAILS_DATE_FAILURE,
  TOTAL_NEW_REGISTRATION_SUCCESS,
  TOTAL_NEW_REGISTRATION_FAILURE,
  GENDER_COUNT_FAILURE,
  GENDER_COUNT_SUCCESS,
  All_VOTE_AND_POST_USERS_SUCCESS,
  All_VOTE_AND_POST_USERS_FAILURE,
} from "../constants/index";

export function getTotalUsersReportSuccess(payload) {
  return {
    type: TOTAL_USER_REPORT_SUCCESS,
    payload,
  };
}
export function getTotalUsersReportFailure(payload) {
  return {
    type: TOTAL_USER_REPORT_FAILURE,
    payload,
  };
}

export function getUserPostsReportSuccess(payload) {
  return {
    type: TOTAL_USER_POSTS_REPORT_SUCCESS,
    payload,
  };
}
export function getUserPostsReportFailure(payload) {
  return {
    type: TOTAL_USER_POSTS_REPORT_FAILURE,
    payload,
  };
}

export function getUserPostsAndVotesReportSuccess(payload) {
  return {
    type: TOTAL_USER_POSTS_VOTES_DETAILS_DATE_SUCCESS,
    payload,
  };
}
export function getUserPostsAndVotesReportFailure(payload) {
  return {
    type: TOTAL_USER_POSTS_VOTES_DETAILS_DATE_FAILURE,
    payload,
  };
}

export function getNewRegistrationSuccess(payload) {
  return {
    type: TOTAL_NEW_REGISTRATION_SUCCESS,
    payload,
  };
}
export function getNewRegistrationFailure(payload) {
  return {
    type: TOTAL_NEW_REGISTRATION_FAILURE,
    payload,
  };
}

export function getGenderCountSuccess(payload) {
  return {
    type: GENDER_COUNT_SUCCESS,
    payload,
  };
}
export function getGenderCountFailure(payload) {
  return {
    type: GENDER_COUNT_FAILURE,
    payload,
  };
}

export function getPostAndVoteByUserSuccess(payload) {
  return {
    type: All_VOTE_AND_POST_USERS_SUCCESS,
    payload,
  };
}
export function getPostAndVoteByUserFailure(payload) {
  return {
    type: All_VOTE_AND_POST_USERS_FAILURE,
    payload,
  };
}

export function getTotalUserReport() {
  return async (dispatch) => {
    const response = await get(`dashboard/user/top-users/activity/details`);
    if (response && response.status === 200 && response.data) {
      dispatch(getTotalUsersReportSuccess(response.data));
    } else {
      dispatch(getTotalUsersReportFailure("Please Try again"));
    }
  };
}
export function getUserPostsReport() {
  return async (dispatch) => {
    const response = await get(`dashboard/users/average/details`);
    if (response && response.status === 200 && response.data) {
      dispatch(getUserPostsReportSuccess(response.data));
    } else {
      dispatch(getUserPostsReportFailure("Please Try again"));
    }
  };
}

export function getUserPostsAndVotesReport(startDate, endDate) {
  return async (dispatch) => {
    const response = await get(`dashboard/users/posts-votes/details-by-date`, {
      params: {
        startDate,
        endDate,
      },
    });
    if (response && response.status === 200 && response.data) {
      dispatch(getUserPostsAndVotesReportSuccess(response.data));
    } else {
      dispatch(getUserPostsAndVotesReportFailure("Please Try again"));
    }
  };
}

export function getNewRegistrationData(startDate, endDate) {
  return async (dispatch) => {
    const response = await get(`dashboard/users/details/new-registration`, {
      params: {
        startDate,
        endDate,
      },
    });
    if (response && response.status === 200 && response.data) {
      dispatch(getNewRegistrationSuccess(response.data));
    } else {
      dispatch(getNewRegistrationFailure("Please Try again"));
    }
  };
}

export function getGenderData() {
  return async (dispatch) => {
    const response = await get(`dashboard/user/gender-ratio`);
    if (response && response.status === 200 && response.data) {
      dispatch(getGenderCountSuccess(response.data));
    } else {
      dispatch(getGenderCountFailure("Please Try again"));
    }
  };
}

export function getTotalUserByPostAndVote() {
  return async (dispatch) => {
    const response = await get(`dashboard/number-Of-user/post-vote`);
    if (response && response.status === 200 && response.data) {
      dispatch(getPostAndVoteByUserSuccess(response.data));
    } else {
      dispatch(getPostAndVoteByUserFailure("Please Try again"));
    }
  };
}
