import { get, patch } from "../api/axios";
import {
  GET_ANONYMOUS_ACTION_LIST_FAILURE,
  GET_ANONYMOUS_ACTION_LIST_SUCCESS,
  UPDATE_ANONYMOUSVOTE_PORTAL_STATUS_FAILURE,
  UPDATE_ANONYMOUSVOTE_PORTAL_STATUS_SUCCESS,
} from "../constants";

export function updateAnonymousVoteStatusSuccess(payload) {
  return {
    type: UPDATE_ANONYMOUSVOTE_PORTAL_STATUS_SUCCESS,
    payload,
  };
}
export function updateAnonymousVoteStatusFailure(payload) {
  return {
    type: UPDATE_ANONYMOUSVOTE_PORTAL_STATUS_FAILURE,
    payload,
  };
}

export function getAnonymousActionListSuccess(payload) {
  return {
    type: GET_ANONYMOUS_ACTION_LIST_SUCCESS,
    payload,
  };
}
export function getAnonymousActionListFailure(payload) {
  return {
    type: GET_ANONYMOUS_ACTION_LIST_FAILURE,
    payload,
  };
}
export function updateAnonymousVoteStatus(portalVal, status) {
  return async (dispatch) => {
    const response = await patch(
      `dashboard/update/anonymousvote/status/byPortal?portal=${portalVal}&status=${status}`
    );

    if (response && response.status === 200 && response.data) {
      dispatch(updateAnonymousVoteStatusSuccess(response.data));
    } else {
      dispatch(updateAnonymousVoteStatusFailure("Please Try again"));
    }
  };
}

export function getAnonymousActionList() {
  return async (dispatch) => {
    const response = await get(`dashboard/get/allPortal/anonymousvote/status`);
    if (response && response.status === 200 && response.data) {
      dispatch(getAnonymousActionListSuccess(response.data));
    } else {
      dispatch(getAnonymousActionListFailure("Please Try again"));
    }
  };
}
