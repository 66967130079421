import React from "react";
import Highcharts from "highcharts";
import Card from "@mui/material/Card";
import VennModule from "highcharts/modules/venn.js";
import HighchartsReact from "highcharts-react-official";
import "./index.css";
VennModule(Highcharts);
const UserCountByVoteAndPost = ({
  totalCountByPostAndVote,
  totalUserCounts,
}) => {
  const [value, setValue] = React.useState();
  React.useEffect(() => {
    if (totalCountByPostAndVote != "" && totalCountByPostAndVote != undefined) {
      setValue(totalCountByPostAndVote);
    }
  }, [totalCountByPostAndVote]);
  let vennOptions = {
    title: {
      text: "",
    },

    chart: {
      className: "venn_Charts",
      animation: true,
      height: 332,
      width: 380,
      opacity: "1",
    },
    credits: {
      enabled: false,
    },

    series: [
      {
        type: "venn",
        data: value,
        name: "",

        dataLabels: {
          enabled: true,

          style: {
            fontSize: "12px",
            fontWeight: "600",
            fontFamily: "Montserrat",
          },
        },
      },
    ],
  };
  return (
    <>
      <Card sx={{ m: 4, boxShadow: 2 }} style={{ width: "93%" }}>
        <p className="top-user-heading">
          Number of Users - vote and post count
        </p>
        <div className="user-count-by-vote-and-post-charts">
          <HighchartsReact highcharts={Highcharts} options={vennOptions} />
        </div>
        <section className="both-post-vote-user-tatal-count">
          Total User: {totalUserCounts}
        </section>
      </Card>
    </>
  );
};
export default UserCountByVoteAndPost;
