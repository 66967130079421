import React, { useState } from "react";
import { Pagination } from "@mui/material";
import { useSelector } from "react-redux";
import DataTable from "../../DataTable";
import { DataFilterByPortal } from "../../DataFilterByPortal";

const Ethnicity = ({ portal, setPortal }) => {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  const allVoteByEthnicity = useSelector(
    (state) => state.userData.voteByEthnicity.voteCountByEthnicity
  );

  const allEthnicitiesData = allVoteByEthnicity?.map((vote) => ({
    portalType: vote.portalType,
    ethnicity: Array.isArray(vote.ethnicity)
      ? vote.ethnicity.join(", ")
      : vote.ethnicity,
    userVoteCount: vote.userVoteCount,
    percentage: vote.percentage,
  }));

  const allLabels = [
    { key: "portalType", label: "Portal Type" },
    { key: "ethnicity", label: "Ethnicity" },
    { key: "userVoteCount", label: "User Vote Count" },
    { key: "percentage", label: "Percentage" },
  ];

  const ethnicityTotalCount = allVoteByEthnicity?.length || 0;

  return (
    <>
      <DataFilterByPortal portal={portal} setPortal={setPortal} />
      <DataTable
        data={allEthnicitiesData?.slice(
          (page - 1) * rowsPerPage,
          page * rowsPerPage
        )}
        columns={allLabels}
      />
      {ethnicityTotalCount > rowsPerPage && (
        <Pagination
          count={Math.ceil(ethnicityTotalCount / rowsPerPage)}
          onChange={(event, value) => setPage(value)}
          color="secondary"
          shape="rounded"
          style={{ margin: "auto" }}
        />
      )}
    </>
  );
};

export default Ethnicity;
