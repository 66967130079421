import { post } from "../api/axios";
import {
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
} from "../constants";

export function receiveLogin(payload) {
  window.location.href = "/dashboard";
  return {
    type: LOGIN_SUCCESS,
    payload,
  };
}

function loginError(payload) {
  return {
    type: LOGIN_FAILURE,
    payload,
  };
}

function requestLogout() {
  return {
    type: LOGOUT_REQUEST,
  };
}

export function receiveLogout() {
  window.location.href = "/";
  return {
    type: LOGOUT_SUCCESS,
  };
}

export function logoutUser() {
  return (dispatch) => {
    dispatch(requestLogout());
    localStorage.removeItem("authenticated");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userId");
    localStorage.removeItem("userRole");
    localStorage.removeItem("userName");
    localStorage.removeItem("userEmail");
    localStorage.removeItem("loginTime");
    dispatch(receiveLogout());
  };
}

export function loginUser(creds) {
  return async (dispatch) => {
    if (creds.email.length > 0 && creds.password.length > 0) {
      const response = await post("auth/signin", creds);
      if (response && response.data) {
        const { data } = response;
       if (
          data.user &&
          (data.user.roles.includes("admin") ||
            data.user.roles.includes("superadmin"))
        ) {
          dispatch(receiveLogin(data));
          localStorage.setItem("authenticated", true);
          localStorage.setItem("loginTime", Math.floor(Date.now() / 1000));
          localStorage.setItem("accessToken", data.tokens.accessToken);
          localStorage.setItem("userId", data.user.id);
          localStorage.setItem("userName", data.user.name);
          localStorage.setItem("name", data.user.username);
          localStorage.setItem("userEmail", data.user.email);
          localStorage.setItem("portalType", data.user.portalType);
          localStorage.setItem("userRole", data.user.roles[0]);
        } else {
          dispatch(loginError("User not authorized"));
        }
      } else {
        dispatch(loginError("Something went wrong. Please try again."));
      }
    } else {
      dispatch(loginError("Empty username or password"));
    }
  };
}
