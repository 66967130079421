import React, { useState, useMemo, useCallback } from "react";
import { useSelector } from "react-redux";
import DataTable from "../../DataTable";
import {  Grid,Pagination } from "@mui/material";
import { Graph } from "../Graph";
import styled from "styled-components";

const Button = styled.button`
  margin-right: 0.5rem;
  padding: 15px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: ${({ active }) => (active ? "#7C10A7" : "")};
  color: ${({ active }) => (active ? "white" : "")};
`;

const CategoryWrapper = styled.div`
  margin-top: 3rem;
  text-align: center;
`;
export const List = () => {
  const categoriesValue = useSelector(
    (state) => state.categoriesData.categoryData
  );
  const portalType = localStorage.getItem("portalType");
  const categoriesData = useMemo(
    () =>
      categoriesValue.categorylist?.map((category) => {
        const userCount = categoriesValue.categoryUsercount?.find(
          (item) => item.category === category.category
        );
        const postCount = categoriesValue.postCount?.find(
          (item) => item.category === category.category
        );
        const categoryName = category.icon
          ? ` ${category.icon} ${category.title}`
          : category.title;

        return {
          id: category.category,
          category: categoryName,
          experts: userCount?.totalusers || 0,
          questions: postCount?.postcount || 0,
          answers: postCount?.totalvotes || 0,
        };
      }),
    [categoriesValue]
  );

  const categoryLabels = [
    { key: "category", label: "Category" },
    { key: "experts", label: "# Experts" },
    { key: "questions", label: "# Questions" },
    { key: "answers", label: "# Answers" },
  ];

  const [page, setPage] = useState(1);
  const rowsPerPage = 20;
  const indexOfLastRow = page * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const categoryData =
    categoriesData?.slice(indexOfFirstRow, indexOfLastRow) || [];
  const handleChangePage = useCallback((event, newPage) => {
    setPage(parseInt(newPage, 10));
  }, []);

  const [showChart, setShowChart] = useState(false);

  return (
    <>
    <Grid
  item
  xs={12}
  md={12}
  container
  justifyContent="center"
  alignItems="center"
  style={{ margin: "auto" }}
>
  <CategoryWrapper>
    <Button active={!showChart} onClick={() => setShowChart(false)}>
      All Categories
    </Button>
    <Button active={showChart} onClick={() => setShowChart(true)}>
      Top 10 Categories
    </Button>
  </CategoryWrapper>

  {showChart ? (
    <Grid
      item
      xs={12}
      md={10}
      justifyContent="center"
      alignItems="center"
      style={{ margin: "auto", marginTop: "6rem" }}
    >
      <Graph />
    </Grid>
  ) : (
    <>
      <DataTable data={categoryData} columns={categoryLabels} />
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ margin: "auto", marginTop: "1rem" }}
      >
        <Pagination
          count={Math.ceil((categoriesData?.length || 0) / rowsPerPage)}
          page={page}
          onChange={handleChangePage}
          color="secondary"
          shape="rounded"
        />
      </Grid>
    </>
  )}
</Grid>

    </>
  );
};
