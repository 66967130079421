import { del, get, patch, post } from "../api/axios";
import {
  ALL_USER_DATA_SUCCESS,
  ALL_USER_DATA_FAILURE,
  ALL_USER_DETAIL_FAILURE,
  ALL_USER_DETAIL_SUCCESS,
  USER_ROLE_SUCCESS,
  USER_ROLE_FAILURE,
  USER_BAN_SUCCESS,
  USER_BAN_FAILURE,
  USER_UNBAN_SUCCESS,
  USER_UNBAN_FAILURE,
  ALL_USER_DATA_EXPORT_SUCCESS,
  ALL_USER_DATA_EXPORT_FAILURE,
  ALL_USER_DATA_SEARCH_SUCCESS,
  ALL_USER_DATA_SEARCH_FAILURE,
  VOTEBY_PORTALTYPE_SUCCESS,
  VOTEBY_PORTALTYPE_FAILURE,
  VOTEBY_PORTALTYPE_COUNT_SUCCESS,
  VOTEBY_PORTALTYPE_COUNT_FAILURE,
  ALL_VOTE_COUNT_BY_AGE_RANGE_SUCCESS,
  ALL_VOTE_COUNT_BY_AGE_RANGE_FAILURE,
  ALL_VOTE_COUNT_BY_TIME_SUCCESS,
  ALL_VOTE_COUNT_BY_TIME_FAILURE,
  ALL_VOTE_BY_ETHNICITY_SUCCESS,
  ALL_VOTE_BY_ETHNICITY_FAILURE,
  USER_BY_VOTE_SUCCESS,
  USER_BY_VOTE_FAILURE,
  TOP_CATEGORIES_BYVOTE_SUCCESS,
  TOP_CATEGORIES_BYVOTE_FAILURE,
  QUESTION_VOTECOUNT_BY_AGE_SUCCESS,
  QUESTION_VOTECOUNT_BY_AGE_FAILURE,
  QUESTION_VOTECOUNT_BY_EHNICITY_SUCCESS,
  QUESTION_VOTECOUNT_BY_EHNICITY_FAILURE,
  CREATE_POST_TAG_SUCCESS,
  CREATE_POST_TAG_FAILURE,
  GET_POST_FOR_TAG_SUCCESS,
  GET_POST_FOR_TAG_FAILURE,
  GET_POST_AND_TAG_DATA_FAILURE,
  GET_POST_AND_TAG_DATA_SUCCESS,
  GET_SEARCH_POST_FOR_TAG_SUCCESS,
  GET_SEARCH_POST_FOR_TAG_FAILURE,
  GET_COUNT_FOR_TAG_SUCCESS,
  GET_COUNT_FOR_TAG_FAILURE,
  GET_SEARCH_RESULT_FOR_TAG_FAILURE,
  GET_SEARCH_RESULT_FOR_TAG_SUCCESS,
  GET_CLOSED_POST_COUNT_SUCCESS,
  GET_CLOSED_POST_COUNT_FAILURE,
} from "../constants";

export function getUsersDataSuccess(payload) {
  return {
    type: ALL_USER_DATA_SUCCESS,
    payload,
  };
}
export function getUsersDataFailure(payload) {
  return {
    type: ALL_USER_DATA_FAILURE,
    payload,
  };
}
export function getUsersDetailSuccess(payload) {
  return {
    type: ALL_USER_DETAIL_SUCCESS,
    payload,
  };
}
export function getUsersDetailFailure(payload) {
  return {
    type: ALL_USER_DETAIL_FAILURE,
    payload,
  };
}

export function getUsersDataExportSuccess(payload) {
  return {
    type: ALL_USER_DATA_EXPORT_SUCCESS,
    payload,
  };
}
export function getUsersDataExportFailure(payload) {
  return {
    type: ALL_USER_DATA_EXPORT_FAILURE,
    payload,
  };
}
export function getUsersDataSearchSuccess(payload) {
  return {
    type: ALL_USER_DATA_SEARCH_SUCCESS,
    payload,
  };
}
export function getUsersDataSearchFailure(payload) {
  return {
    type: ALL_USER_DATA_SEARCH_FAILURE,
    payload,
  };
}

export function getUsersRoleSuccess(payload) {
  return {
    type: USER_ROLE_SUCCESS,
    payload,
  };
}
export function getUsersRoleFailure(payload) {
  return {
    type: USER_ROLE_FAILURE,
    payload,
  };
}
export function getUserBanFailure(payload) {
  return {
    type: USER_BAN_FAILURE,
    payload,
  };
}
export function getUserBanSuccess(payload) {
  return {
    type: USER_BAN_SUCCESS,
    payload,
  };
}
export function getUserUnBanFailure(payload) {
  return {
    type: USER_UNBAN_FAILURE,
    payload,
  };
}
export function getUserUnBanSuccess(payload) {
  return {
    type: USER_UNBAN_SUCCESS,
    payload,
  };
}

export function getUserVoteByPortalTypeFailure(payload) {
  return {
    type: VOTEBY_PORTALTYPE_FAILURE,
    payload,
  };
}
export function getUserVoteByPortalTypeSuccess(payload) {
  return {
    type: VOTEBY_PORTALTYPE_SUCCESS,
    payload,
  };
}
export function getVoteDataByPortalTypeCountFailure(payload) {
  return {
    type: VOTEBY_PORTALTYPE_COUNT_FAILURE,
    payload,
  };
}
export function getVoteDataByPortalTypeCountSuccess(payload) {
  return {
    type: VOTEBY_PORTALTYPE_COUNT_SUCCESS,
    payload,
  };
}

export function getVoteCountByTimeSuccess(payload) {
  return {
    type: ALL_VOTE_COUNT_BY_TIME_SUCCESS,
    payload,
  };
}
export function getVoteCountByTimeFailure(payload) {
  return {
    type: ALL_VOTE_COUNT_BY_TIME_FAILURE,
    payload,
  };
}

export function getVoteCountByAgeRangeSuccess(payload) {
  return {
    type: ALL_VOTE_COUNT_BY_AGE_RANGE_SUCCESS,
    payload,
  };
}
export function getVoteCountByAgeRangeFailure(payload) {
  return {
    type: ALL_VOTE_COUNT_BY_AGE_RANGE_FAILURE,
    payload,
  };
}
export function getVoteByEthnicitySuccess(payload) {
  return {
    type: ALL_VOTE_BY_ETHNICITY_SUCCESS,
    payload,
  };
}
export function getVoteByEthnicityFailure(payload) {
  return {
    type: ALL_VOTE_BY_ETHNICITY_FAILURE,
    payload,
  };
}
export function getUserCountByVoteSuccess(payload) {
  return {
    type: USER_BY_VOTE_SUCCESS,
    payload,
  };
}
export function getUserCountByVoteFailure(payload) {
  return {
    type: USER_BY_VOTE_FAILURE,
    payload,
  };
}
export function getTopCategoryByVoteSuccess(payload) {
  return {
    type: TOP_CATEGORIES_BYVOTE_SUCCESS,
    payload,
  };
}
export function getTopCategoryByVoteFailure(payload) {
  return {
    type: TOP_CATEGORIES_BYVOTE_FAILURE,
    payload,
  };
}
export function getQuestionVoteCountByAgeSuccess(payload) {
  return {
    type: QUESTION_VOTECOUNT_BY_AGE_SUCCESS,
    payload,
  };
}
export function getQuestionVoteCountByAgeFailure(payload) {
  return {
    type: QUESTION_VOTECOUNT_BY_AGE_FAILURE,
    payload,
  };
}

export function getQuestionVoteByEthnicitySuccess(payload) {
  return {
    type: QUESTION_VOTECOUNT_BY_EHNICITY_SUCCESS,
    payload,
  };
}
export function getQuestionVoteByEthnicityFailure(payload) {
  return {
    type: QUESTION_VOTECOUNT_BY_EHNICITY_FAILURE,
    payload,
  };
}

export function createTagForPostSuccess(payload) {
  return {
    type: CREATE_POST_TAG_SUCCESS,
    payload,
  };
}
export function createTagForPostFailure(payload) {
  return {
    type: CREATE_POST_TAG_FAILURE,
    payload,
  };
}

export function getPostForTagSuccess(payload) {
  return {
    type: GET_POST_FOR_TAG_SUCCESS,
    payload,
  };
}
export function getPostForTagFailure(payload) {
  return {
    type: GET_POST_FOR_TAG_FAILURE,
    payload,
  };
}

export function getSearchPostForTagSuccess(payload) {
  return {
    type: GET_SEARCH_POST_FOR_TAG_SUCCESS,
    payload,
  };
}
export function getSearchPostForTagFailure(payload) {
  return {
    type: GET_SEARCH_POST_FOR_TAG_FAILURE,
    payload,
  };
}

export function getSearchResultForTagSuccess(payload) {
  return {
    type: GET_SEARCH_RESULT_FOR_TAG_SUCCESS,
    payload,
  };
}
export function getSearchResultForTagFailure(payload) {
  return {
    type: GET_SEARCH_RESULT_FOR_TAG_FAILURE,
    payload,
  };
}

export function getClosedPostCountSuccess(payload) {
  return {
    type: GET_CLOSED_POST_COUNT_SUCCESS,
    payload,
  };
}
export function getClosedPostCountFailure(payload) {
  return {
    type: GET_CLOSED_POST_COUNT_FAILURE,
    payload,
  };
}
export function getUserData(type, page, limit) {
  return async (dispatch) => {
    const response = await get(`dashboard/junkee/users`, {
      params: { portal: type, page, limit: 20 },
    });
    if (response && response.status === 200 && response.data) {
      dispatch(getUsersDataSuccess(response.data));
    } else {
      dispatch(getUsersDataFailure("Please Try again"));
    }
  };
}

export function getUserDataSearch(searchedVal) {
  return async (dispatch) => {
    const response = await get(`dashboard/search/users`, {
      params: { usernames: searchedVal },
    });

    if (response && response.status === 200 && response.data) {
      dispatch(getUsersDataSearchSuccess(response.data));
    } else {
      dispatch(getUsersDataSearchFailure("Please Try again"));
    }
  };
}
export function getExportUserData() {
  return async (dispatch) => {
    const response = await get(`dashboard/admin/data/export`);
    if (response && response.status === 200 && response.data) {
      dispatch(getUsersDataExportSuccess(response.data));
    } else {
      dispatch(getUsersDataExportFailure("Please Try again"));
    }
  };
}

export function getUserDetails(userId) {
  return async (dispatch) => {
    const response = await get(`dashboard/users/${userId}`);
    if (response && response.status === 200 && response.data) {
      dispatch(getUsersDetailSuccess(response.data));
    } else {
      dispatch(getUsersDetailFailure("Please Try again"));
    }
  };
}

export function getUserRole(userId) {
  return async (dispatch) => {
    const response = await get(`dashboard/users/roles/${userId}`);
    if (response && response.status === 200 && response.data) {
      dispatch(getUsersRoleSuccess(response.data));
    } else {
      dispatch(getUsersRoleFailure("Please Try again"));
    }
  };
}
export const userBan = (userId) => {
  return async (dispatch) => {
    const response = await patch(`dashboard/admin/ban/${userId}`);
    if (response && response.status === 200 && response.data) {
      dispatch(getUserBanSuccess(response.data));
    } else {
      dispatch(getUserBanFailure("Please Try again"));
    }
  };
};
export const userUnBan = (userId) => {
  return async (dispatch) => {
    const response = await patch(`dashboard/admin/unban/${userId}`);
    if (response && response.status === 200 && response.data) {
      dispatch(getUserUnBanSuccess(response.data));
    } else {
      dispatch(getUserUnBanFailure("Please Try again"));
    }
  };
};

export function getUserVoteDataByPortalType(type, page, limit, username) {
  return async (dispatch) => {
    const response = await get(`dashboard/post/votecount-portaltype`, {
      params: { portal: type, page, limit: 20, username },
    });
    if (response && response.status === 200 && response.data) {
      dispatch(getUserVoteByPortalTypeSuccess(response.data));
    } else {
      dispatch(getUserVoteByPortalTypeFailure("Please Try again"));
    }
  };
}

export function getVoteDataByPortalTypeCount(type, username) {
  return async (dispatch) => {
    const response = await get(`dashboard/post/countByPortalType`, {
      params: { portal: type, username },
    });

    if (response && response.status === 200 && response.data) {
      dispatch(getVoteDataByPortalTypeCountSuccess(response.data));
    } else {
      dispatch(getVoteDataByPortalTypeCountFailure("Please Try again"));
    }
  };
}

export function getVoteCountByAgeRange(type) {
  return async (dispatch) => {
    const response = await get(`dashboard/voteCount/ByPortalType/Age-Range`, {
      params: { portal: type },
    });
    if (response && response.status === 200 && response.data) {
      dispatch(getVoteCountByAgeRangeSuccess(response.data));
    } else {
      dispatch(getVoteCountByAgeRangeFailure("Please Try again"));
    }
  };
}

export function getVoteCountByTime(type) {
  return async (dispatch) => {
    const response = await get(`dashboard/portalVoteCount/hour-wise`, {
      params: { portal: type },
    });
    if (response && response.status === 200 && response.data) {
      dispatch(getVoteCountByTimeSuccess(response.data));
    } else {
      dispatch(getVoteCountByTimeFailure("Please Try again"));
    }
  };
}

export function getVoteByEthnicity(type) {
  return async (dispatch) => {
    const response = await get(`dashboard/portalVoteCount/by-ethnicity`, {
      params: { portal: type },
    });
    if (response && response.status === 200 && response.data) {
      dispatch(getVoteByEthnicitySuccess(response.data));
    } else {
      dispatch(getVoteByEthnicityFailure("Please Try again"));
    }
  };
}
export function getUserCountByVote(type) {
  return async (dispatch) => {
    const response = await get(`dashboard/UserCount/Votes/byPortal`, {
      params: { portal: type },
    });
    if (response && response.status === 200 && response.data) {
      dispatch(getUserCountByVoteSuccess(response.data));
    } else {
      dispatch(getUserCountByVoteFailure("Please Try again"));
    }
  };
}

export function getTopCategoriesByVote(type, filterOptions) {
  return async (dispatch) => {
    const response = await get(
      `dashboard/categoryList/question-answer-count/byPortal`,
      {
        params: { portal: type, "filter text": filterOptions },
      }
    );
    if (response && response.status === 200 && response.data) {
      dispatch(getTopCategoryByVoteSuccess(response.data));
    } else {
      dispatch(getTopCategoryByVoteFailure("Please Try again"));
    }
  };
}

export function getQuestionVoteCountByAge(type) {
  return async (dispatch) => {
    const response = await get(
      `dashboard/question/voteCount/ByPortalType/Age-Range`,
      {
        params: { portal: type },
      }
    );

    if (response && response.status === 200 && response.data) {
      dispatch(getQuestionVoteCountByAgeSuccess(response.data));
    } else {
      dispatch(getQuestionVoteCountByAgeFailure("Please Try again"));
    }
  };
}

export function getQuestionVoteCountByEthnicity(type) {
  return async (dispatch) => {
    const response = await get(
      `dashboard/Question/portalVoteCount/by-ethnicity`,
      {
        params: { portal: type },
      }
    );

    if (response && response.status === 200 && response.data) {
      dispatch(getQuestionVoteByEthnicitySuccess(response.data));
    } else {
      dispatch(getQuestionVoteByEthnicityFailure("Please Try again"));
    }
  };
}

export function createPostTag(payload) {
  return async () => {
    const response = await post("dashboard/create/postTag", payload);
  };
}

export function getPostForTag(portal, limitVal, pageVal, status, name) {
  return async (dispatch) => {
    const response = await get(`dashboard/getPost/byPortal`, {
      params: { portal, limit: limitVal, page: pageVal, postclosed: status, username: name },
    });
    if (response && response.status === 200 && response.data) {
      dispatch(getPostForTagSuccess(response.data));
    } else {
      dispatch(getPostForTagFailure("Please Try again"));
    }
  };
}

export function deletePostByTag(id) {
  return async () => {
    const response = await del(`dashboard/delete/Tag/byId?id=${id}`);
  };
}

export function getSearchDataForPost(type, value, postStatus, username) {
  return async (dispatch) => {
    const response = await get(`dashboard/search/Post/byPortal`, {
      params: {
        portal: type,
        text: value,
        postclose: postStatus,
        username: username,
      },
    });
    if (response && response.status === 200 && response.data) {
      dispatch(getSearchPostForTagSuccess(response.data));
    } else {
      dispatch(getSearchPostForTagFailure("Please Try again"));
    }
  };
}

export function getSearchDataWithTag(type, value) {
  return async (dispatch) => {
    const response = await get(`dashboard/search/Post/byTag`, {
      params: { portal: type, text: value },
    });
    if (response && response.status === 200 && response.data) {
      dispatch(getSearchResultForTagSuccess(response.data));
    } else {
      dispatch(getSearchResultForTagFailure("Please Try again"));
    }
  };
}

export function getClosedPostCount(type, value, username) {

  return async (dispatch) => {
    const response = await get(`dashboard/post/count/true/false/ByPortalType`, {
      params: { portal: type, postclose: value, username: username },
    });
    if (response && response.status === 200 && response.data) {
      dispatch(getClosedPostCountSuccess(response.data));
    } else {
      dispatch(getClosedPostCountFailure("Please Try again"));
    }
  };
}
