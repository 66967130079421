import React, { useState } from "react";
import { Pagination } from "@mui/material";
import { useSelector } from "react-redux";
import DataTable from "../../DataTable";




export const Ethnicity = ({ portal, setPortal }) => {
  const portalType = localStorage.getItem("portalType");

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(20);
 
  const allQuestionVoteByEthnicity = useSelector(
    (state) => state.userData.QuestionVoteByEthnicity?.voteCountByEthnicity
  );

  const adminData = allQuestionVoteByEthnicity?.filter(
    (item) => item.portalType === portalType
  );

  const combinedData = allQuestionVoteByEthnicity
    ?.filter(
      (item) => item.portalType !== portalType && item.portalType !== "anonymous"
    )
    .reduce((accumulator, item) => {
      const ethnicity = Array.isArray(item.ethnicity)
        ? item.ethnicity.join(", ")
        : item.ethnicity;
      const existingItem = accumulator.find(
        (accItem) => accItem.ethnicity === ethnicity
      );

      if (existingItem) {
        existingItem.userVoteCount += parseInt(item.userVoteCount);
      } else {
        accumulator.push({
          portalType: "Community",
          ethnicity,
          userVoteCount: parseInt(item.userVoteCount),
          percentage: "",
        });
      }

      return accumulator;
    }, []);

  const finalData = [...(adminData || []), ...(combinedData || [])];

  const anonymousData = allQuestionVoteByEthnicity?.filter(
    (item) => item.portalType === "anonymous"
  );

  if (anonymousData) {
    finalData.push(...anonymousData);
  }

  const totalVotes = finalData.reduce(
    (accumulator, item) => accumulator + parseInt(item.userVoteCount),
    0
  );

  const finalDataWithPercentage = finalData.map((item) => ({
    ...item,
    percentage:
      totalVotes > 0
        ? ((parseInt(item.userVoteCount) / totalVotes) * 100).toFixed(2) + "%"
        : "0%",
  }));

  const labels = [
    { key: "portalType", label: "Portal Type" },
    { key: "ethnicity", label: "Ethnicity" },
    { key: "userVoteCount", label: "User Vote Count" },
    { key: "percentage", label: "Percentage" },
  ].filter((label) => label !== null);

  const ethnicityTotalCount = finalDataWithPercentage.length;

  return (
    <>
      <DataTable
        data={finalDataWithPercentage.slice(
          (page - 1) * rowsPerPage,
          page * rowsPerPage
        )}
        columns={labels}
      />

      {ethnicityTotalCount > 20 && (
        <Pagination
          count={Math.ceil(ethnicityTotalCount / rowsPerPage)}
          onChange={(event, value) => setPage(value)}
          color="secondary"
          shape="rounded"
          style={{ margin: "auto" }}
        />
      )}
    </>
  );
};
