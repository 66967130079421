const domainMapping = new Map([
  ["All", ""],
  ["winepilot", "winepilot"],
  ["junkee", "Junkee_Admin"],
  ["wizer", "Wizer"],
  ["techdiversity", "TechDiversity"],
]);

export const getUserNameByPortal = (portal) => {
  const username = domainMapping.get(portal) || "";
  return { username };
};
