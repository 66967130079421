import React from "react";
import Chart from "react-apexcharts";
import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import Card from "@mui/material/Card";
import "./index.css";
const PostAndVotePercentage = () => {
  const userPostsReports = useSelector(
    (state) => state.totalUserReport.userPostsReport
  );
  const reportValue = Object.values(userPostsReports);
  const seriesData = [];

  if (reportValue.length > 0) {
    seriesData.push(reportValue[0].averagepost);
    seriesData.push(reportValue[0].averagecomment);
    seriesData.push(reportValue[0].averagevote);
  }

  const postSeriesData = [];

  if (reportValue.length > 0) {
    postSeriesData.push(reportValue[0].postPercentage);
    postSeriesData.push(100 - reportValue[0].postPercentage);
  }
  const postSeries = [{ data: postSeriesData, name: "User posted" }];

  const postOptions = {
    labels: ["User posted %", "User not posted %"],
    plotOptions: {
      bar: {
        barHeight: "100%",
        distributed: true,
        dataLabels: {
          position: "center",
        },
      },
    },
    colors: ["#277BC0", "#FFB200"],
  };

  const voteSeriesData = [];

  if (reportValue.length > 0) {
    voteSeriesData.push(reportValue[0].votePercentage);
    voteSeriesData.push(100 - reportValue[0].votePercentage);
  }
  const VoteSeries = [{ data: voteSeriesData, name: "User voted " }];
  const VoteOptions = {
    labels: ["User voted %", "User not voted %"],
    plotOptions: {
      bar: {
        barHeight: "100%",
        distributed: true,
        dataLabels: {
          position: "center",
        },
      },
    },
    colors: ["#277BC0", "#FFB200"],
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid md={6} xs={12}>
          <Card sx={{ m: 4, boxShadow: 2 }} style={{ width: "93%" }}>
            <p className="top-user-heading">% of user posted</p>
            <div className="charts">
              {" "}
              <Chart
                type="pie"
                series={postSeries[0].data}
                options={postOptions}
              ></Chart>
            </div>
          </Card>
        </Grid>
        <Grid md={6} xs={12}>
          <Card sx={{ m: 4, boxShadow: 2 }} style={{ width: "92%" }}>
            <p className="top-user-heading">% of user voted</p>
            <div className="charts">
              {" "}
              <Chart
                type="pie"
                series={VoteSeries[0].data}
                options={VoteOptions}
              ></Chart>
            </div>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default PostAndVotePercentage;
