import React from "react";
import { useSelector } from "react-redux";
import Chart from "react-apexcharts";
import DataTable from "../../DataTable";
import { Grid } from "@mui/material";

export const AgeRange = () => {

  const questionVoteByAges = useSelector(
    (state) => state.userData.QuestionVoteByAge.voteCount
  );
  const categories = ["18-30", "31-40", "40 above", "Not set"];
  const portalType = localStorage.getItem("portalType");

  const winePilotData = questionVoteByAges
    ?.filter((data) => data.portal === portalType)
    .sort((a, b) => {
      const aIndex = categories.indexOf(a.age_range);
      const bIndex = categories.indexOf(b.age_range);
      return aIndex - bIndex;
    });

  const winePilotVoteCounts = winePilotData?.map((data) => data.vote_count);

  const anonymousData = questionVoteByAges
    ?.filter((data) => data.portal === "anonymous")
    .sort((a, b) => {
      const aIndex = categories.indexOf(a.age_range);
      const bIndex = categories.indexOf(b.age_range);
      return aIndex - bIndex;
    });

  const anonymousVoteCounts = [0, 0, 0, 0];

  if (anonymousData) {
    anonymousData.forEach((data) => {
      const category = data.age_range;
      const voteCount = parseInt(data.vote_count);

      if (category === "Not set") {
        anonymousVoteCounts[3] = voteCount;
      }
    });
  }

  const otherData = questionVoteByAges?.filter(
    (data) => data.portal !== portalType && data.portal !== "anonymous"
  );
  const otherVoteCounts = {};

  if (categories && otherData) {
    categories.forEach((category) => {
      otherVoteCounts[category] = 0;
      otherData.forEach((data) => {
        if (data && data.age_range === category) {
          otherVoteCounts[category] += parseInt(data.vote_count);
        }
      });
    });
  }

  const options = {
    chart: {
      type: "area",
      height: 350,
    },
    xaxis: {
      categories: categories,
    },
    legend: {
      position: "bottom",
    },
  };
  const capitalizedPortalType = portalType.charAt(0).toUpperCase() + portalType.slice(1);

  const series = [
    {
      name: capitalizedPortalType,
      data: winePilotVoteCounts,
    },
    {
      name: "Community",
      data: categories?.map((category) => otherVoteCounts[category] || 0),
    },
    {
      name: "Anonymous",
      data: anonymousVoteCounts,
    },
  ];

  const adminLabels = [
    { key: "portal", label: "Portal" },
    { key: "age_range", label: "Age Range" },
    { key: "vote_count", label: "Total Vote Count" },
    { key: "percentage", label: "Total Percentage" },
  ];

  const adminData = [];
  categories.forEach((category) => {
    adminData.push({
      portal: portalType,
      age_range: category,
      vote_count:
        winePilotData?.find((data) => data.age_range === category)
          ?.vote_count || 0,
      percentage:
        winePilotData?.find((data) => data.age_range === category)
          ?.percentage || "0%",
    });
  });

  categories.forEach((category) => {
    const voteCount = anonymousData?.find((data) => data.age_range === category)?.vote_count || 0;
    const percentage = anonymousData?.find((data) => data.age_range === category)?.percentage || "0%";

    if (voteCount !== 0) {
      adminData.push({
        portal: "anonymous",
        age_range: category,
        vote_count: voteCount,
        percentage: percentage,
      });
    }
  });

  const otherAdminData = {};
  if (otherData) {
    otherData.forEach((data) => {
      if (!otherAdminData[data.age_range]) {
        otherAdminData[data.age_range] = {
          portal: "Community",
          age_range: data.age_range,
          vote_count: parseInt(data.vote_count),
          percentage: parseFloat(data.percentage),
        };
      } else {
        otherAdminData[data.age_range].vote_count += parseInt(data.vote_count);
        otherAdminData[data.age_range].percentage += parseFloat(
          data.percentage
        );
      }
    });
  }

  // sort the keys based on age range
  const sortedKeys = Object.keys(otherAdminData).sort((a, b) => {
    const rangeA = a.split("-")?.map((age) => parseInt(age));
    const rangeB = b.split("-")?.map((age) => parseInt(age));
    if (rangeA[0] !== rangeB[0]) {
      return rangeA[0] - rangeB[0];
    } else {
      return rangeA[1] - rangeB[1];
    }
  });

  // create a new object with sorted keys
  const sortedOtherAdminData = {};
  sortedKeys.forEach((key) => {
    sortedOtherAdminData[key] = otherAdminData[key];
    sortedOtherAdminData[key].percentage =
      sortedOtherAdminData[key].percentage.toFixed(2) + "%";
  });

  Object.values(sortedOtherAdminData).forEach((data) => {
    adminData.push(data);
  });


  return (
    <>
      <Grid container alignItems="center" justifyContent="center">
        <Grid item xs={11}>
          <Chart options={options} series={series} type="area" height={350} />
        </Grid>
      </Grid>
      <DataTable data={adminData} columns={adminLabels} />
    </>
  );
};
