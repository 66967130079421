import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  CardContent,
  CardActions,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  MenuItem,
  Select,
} from "@mui/material";
import {
  getAnonymousActionList,
  updateAnonymousVoteStatus,
} from "../../../../actions/anonymousAction";

export const AnonymousAction = ({ portal }) => {
  const dispatch = useDispatch();
  const [anonymousPortals, setAnonymousPortals] = useState([]); // Define anonymousPortals state
  const anonymousPortalsFromStore = useSelector(
    ({ anonymousVoteHandler }) => anonymousVoteHandler.getAnonymousStatus
  );

  useEffect(() => {
    dispatch(getAnonymousActionList(""));
  }, [dispatch]);

  useEffect(() => {
    setAnonymousPortals(anonymousPortalsFromStore);
  }, [anonymousPortalsFromStore]);

  const handleStatusChange = (event, portalVal) => {
    const status = event.target.value;
    //  the local anonymousPortals array
    const updatedAnonymousPortals = anonymousPortals.map((value) => {
      if (value.portalType === portalVal) {
        return { ...value, anonymousStatus: status };
      }
      return value;
    });

    setAnonymousPortals(updatedAnonymousPortals);
    dispatch(updateAnonymousVoteStatus(portalVal, status));
  };

  const portalType = localStorage.getItem("portalType");

  const tableCellStyle = {
    borderBottom: "none",
    padding: 4,
    fontSize: 16,
    fontWeight: 700,
    paddingTop: 1,
  };

  const headerTitles = ["Portal", "Status", "Update"];

  return (
    <Card sx={{ maxWidth: 310, boxShadow: "none", margin: "auto" }}>
      <CardContent style={{ backgroundColor: "#f7f6fe", height: 300 }}>
        <div className="timeTrend">
          <h3>Anonymous Vote Action</h3>
        </div>
        <TableContainer>
          <Table
            sx={{
              maxWidth: 400,
              boxShadow: "none",
              margin: "auto",
              justifyContent: "end",
            }}
          >
            <TableHead>
              <TableRow>
                {headerTitles.map((title) => (
                  <TableCell key={title} style={tableCellStyle}>
                    {title}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {anonymousPortals &&
                anonymousPortals.map((value, id) => {
                  if (portal === "All" || portal === value.portalType) {
                    return (
                      <TableRow key={id}>
                        <TableCell
                          style={{
                            ...tableCellStyle,
                            padding: 4,
                            fontSize: 16,
                          }}
                        >
                          {`${value.portalType
                            .charAt(0)
                            .toUpperCase()}${value.portalType.slice(1)}`}
                        </TableCell>
                        <TableCell style={{ ...tableCellStyle, padding: 4 }}>
                          {value.anonymousStatus ? "True" : "False"}
                        </TableCell>
                        <TableCell style={{ ...tableCellStyle, padding: 4 }}>
                          <Select
                            size="small"
                            sx={{ fontSize: "14px" }}
                            value={value.anonymousStatus.toString()} // Convert boolean to string
                            onChange={(event) =>
                              handleStatusChange(event, value.portalType)
                            }
                          >
                            <MenuItem
                              value={true}
                              size="small"
                              sx={{
                                fontSize: "14px",
                                backgroundColor: value.anonymousStatus
                                  ? "rgb(124, 16, 167)"
                                  : "transparent",
                              }}
                            >
                              True
                            </MenuItem>
                            <MenuItem
                              value={false}
                              size="small"
                              sx={{
                                fontSize: "14px",
                                backgroundColor: !value.anonymousStatus
                                  ? "rgb(124, 16, 167)"
                                  : "transparent",
                              }}
                            >
                              False
                            </MenuItem>
                          </Select>
                        </TableCell>
                      </TableRow>
                    );
                  } else {
                    return null; // Skip rendering for other portal values
                  }
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
     
    </Card>
  );
};

