import React, { useState, useMemo, useCallback } from "react";
import { useSelector } from "react-redux";
import DataTable from "../DataTable";
import { Pagination, Grid } from "@mui/material";

 const CategoryList = () => {
  const categoriesData = useSelector(state => state.categoriesData.categoryData);
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 20;

  const mappedCategories = useMemo(() => {
    return categoriesData.categorylist?.map(category => {
      const userCount = categoriesData.categoryUsercount?.find(item => item.category === category.category);
      const postCount = categoriesData.postCount?.find(item => item.category === category.category);
      const categoryName = category.icon ? ` ${category.icon} ${category.title}` : category.title;

      return {
        id: category.category,
        category: categoryName,
        experts: userCount?.totalusers || 0,
        questions: postCount?.postcount || 0,
        answers: postCount?.totalvotes || 0
      };
    });
  }, [categoriesData]);

  const categoryLabels = [
    { key: "category", label: "Category" },
    { key: "experts", label: "# Experts" },
    { key: "questions", label: "# Questions" },
    { key: "answers", label: "# Answers" }
  ];

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const paginatedCategories = mappedCategories?.slice(indexOfFirstRow, indexOfLastRow) || [];

  const handleChangePage = useCallback((event, newPage) => {
    setCurrentPage(+newPage);
  }, []);

  const pageCount = Math.ceil((mappedCategories?.length || 0) / rowsPerPage);

  return (
    <Grid
      item
      xs={12}
      md={12}
      justifyContent="center"
      alignItems="center"
      style={{ margin: "auto" }}
    >
      <DataTable data={paginatedCategories} columns={categoryLabels} />
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ margin: "auto", marginTop: "1rem" }}
      >  <Pagination
        count={pageCount}
        page={currentPage}
        onChange={handleChangePage}
        color="secondary"
        shape="rounded"
        style={{ margin: "auto" }}
      />
    </Grid>
    </Grid>
  );
};
export default CategoryList;
