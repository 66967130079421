import React, { useState, useEffect } from "react";
import { ReactComponent as StatsIcon } from "../../../assets/icons/stats.svg";
import { useSelector } from "react-redux";
import { Grid } from "@mui/material";
import { DataFilterByPortal } from "../DataFilterByPortal";
import {
  TopUser,
  TopCategory,
  VoteByTimeTrend,
  AnonymousAction,
} from "../Cards";
import { StatsCard } from "../../SuperAdmin/Cards/StatsCard";

const OverAllStats = ({ setActiveTab, portal, setPortal }) => {
  const expertCount = useSelector((state) => state.overallStats.junkeeCount);
  const [junkeeCount, setJunkeeCount] = useState();

  useEffect(() => {
    if (expertCount != "undefined" && expertCount != "")
      setJunkeeCount(expertCount[0].totalexper);
  }, [expertCount]);
  const handleViewAllUsers = () => {
    if (typeof setActiveTab === "function") {
      setActiveTab(2);
    }
  };
  const portalType = localStorage.getItem("portalType");

  const showTypes = {
    type1: true,
    type2: true,
    type3: portalType !== "winepilot",
  };
  const totalExpert = {
    expertHeading: "TOTAL EXPERTS",
    icon: <StatsIcon />,
    junkeeCounts: junkeeCount,

    expertButtonLabel: "View All Users",
    handleExpertButtonClick: handleViewAllUsers,
  };
  return (
    <>
      <DataFilterByPortal portal={portal} setPortal={setPortal} />

      <Grid
        container
        spacing={2}
        justifyContent="center"
        alignItems="center"
        style={{ maxWidth: 1400, margin: "100px auto" }}
      >
        <Grid item xs={12} md={3}>
          <StatsCard data={totalExpert} showTypes={showTypes} />
        </Grid>
        <Grid item xs={12} md={3}>
          <TopCategory setActiveTab={setActiveTab} showTypes2={showTypes} />
        </Grid>
        {portalType !== "winepilot" && (
          <Grid item xs={12} md={3}>
            <TopUser setActiveTab={setActiveTab} showTypes3={showTypes} />
          </Grid>
        )}
      </Grid>

      <Grid
        container
        spacing={2}
        justifyContent="center"
        alignItems="center"
        style={{ maxWidth: 1400, margin: "40px auto" }}
      >
        <Grid item xs={12} md={3}>
          <VoteByTimeTrend />
        </Grid>
        <Grid item xs={12} md={3}>
          <AnonymousAction portal={portal} />
        </Grid>{" "}
      </Grid>
    </>
  );
};

export default OverAllStats;
