import React, { useState } from "react";
import {
  TableRow,
  TableCell,
  Checkbox,
  FormControlLabel,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import { Clear } from "@mui/icons-material";

export const QuestionTableRow = ({
  question,
  selectedQuestions,
  setSelectedQuestions,
  handleDelete,
  tags,
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleQuestionSelect = (event) => {
    const hasMatchingTags = question.tag.some((tag) =>
      tags.includes(tag.label)
    );
    if (!hasMatchingTags) {
      const questionId = question.postid;
      const isSelected = event.target.checked;
      setSelectedQuestions((prevSelectedQuestions) =>
        isSelected
          ? [...prevSelectedQuestions, questionId]
          : prevSelectedQuestions.filter((id) => id !== questionId)
      );
    } else {
      setIsDialogOpen(true);
    }
  };
  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  return (
    <>
      <Dialog className="scale-in-out" open={isDialogOpen} onClose={handleDialogClose}>
        <DialogTitle
          style={{
            textAlign: "center",
            margin: "auto",
            fontSize: 24,
            fontFamily: "Montserrat",
            color: "black",
            paddingTop: 25,
            fontWeight: 700,
          }}
        >
          Oops !
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            style={{
              textAlign: "center",
              margin: "auto",
              fontSize: 22,
              fontFamily: "Montserrat",
              color: "black",
            }}
          >
            Tag already exist in post
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            style={{
              backgroundColor: "#7C10A7",
              borderRadius: 5,
              margin: "auto",
              fontFamily: "Montserrat",
              fontSize: 18,
            }}
            variant="contained"
            onClick={handleDialogClose}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <TableRow
        sx={{
          bgcolor: "rgb(247, 246, 254)",
          border: "2px solid white",
        }}
      >
        <TableCell
          style={{
            fontSize: 16,
            fontFamily: "Montserrat",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <FormControlLabel
              control={
                <Checkbox
                  size="large"
                  checked={selectedQuestions.includes(question?.postid)}
                  onChange={handleQuestionSelect}
                />
              }
              label={question?.question}
            />
            {question?.tag[0].id !== null && (
              <div
                style={{
                  marginLeft: "45px",
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                {question?.tag?.map((tag) => (
                  <Chip
                    label={tag.label}
                    key={tag.id}
                    size="small"
                    className="animated-chip"
                    sx={{
                      marginRight: 1,
                      marginBottom: 1,
                      backgroundColor: "rgb(124, 16, 167)",
                      color: "white",
                      fontSize: 13,
                      align: "center",
                      fontFamily: "Montserrat",
                    }}
                    deleteIcon={<Clear style={{ color: "white" }} />}
                    onDelete={() => handleDelete(tag?.id)}
                  />
                ))}
              </div>
            )}
          </div>
        </TableCell>

        <TableCell
          style={{
            fontSize: 16,
            fontFamily: "Montserrat",
          }}
        >
          {question.closed == false ? "Undecided" : "Decided"}
        </TableCell>
      </TableRow>
    </>
  );
};
