import * as React from "react";
import { useEffect } from "react";
import "./index.css";
import "react-datepicker/dist/react-datepicker.css";
import { Redirect } from "react-router-dom";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@mui/material";
import { logoutUser } from "../../actions/auth";
import {
  getNewRegistrationData,
  getUserPostsAndVotesReport,
  getUserPostsReport,
  getTotalUserReport,
  getGenderData,
  getTotalUserByPostAndVote,
} from "../../actions/totalUserReport";
import GenderPercentage from "../../components/SuperAdmin/GenderPercentage";
import { getCategorieLeaderboardData } from "../../actions/categoriesData";
import DatePickerInputs from "../../components/SuperAdmin/DatePicker";
import TextualData from "../../components/SuperAdmin/TextualData";
import PostAndVoteByTopUser from "../../components/SuperAdmin/PostAndVoteByTopUser";
import PostAndVotePercentage from "../../components/SuperAdmin/PostAndVotePercentage";
import PortalTypeAndStats from "../../components/SuperAdmin/PortalTypeAndStats";
const ReportPage = () => {
  const [role, setRole] = useSelector((state) => state.userData.userRole);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCategorieLeaderboardData(""));
    dispatch(getGenderData(""));
    dispatch(getUserPostsReport(""));
    dispatch(getTotalUserReport(""));
    dispatch(getUserPostsAndVotesReport(""));
    dispatch(getTotalUserByPostAndVote(""));
    const currTime = Math.floor(Date.now() / 1000);
    const loggedTime = currTime - localStorage.getItem("loginTime");
    if (Math.floor(loggedTime) > 1800) {
      dispatch(logoutUser());
    }
  }, []);
  const [show, setShow] = React.useState(true);
  const [newDate, setNewDate] = React.useState();
  const [startDate, setstartDate] = React.useState();
  const [EndDate, setEndDate] = React.useState();

  const startDateValue = startDate;
  const endDateValue = EndDate;
  const msDay = 24 * 60 * 60 * 1000;

  const days = Math.floor((endDateValue - startDateValue) / msDay);

  const OnFrmDtChange = (Date) => {
    setstartDate(Date);
  };

  const OnToDtChange = (Date) => {
    setEndDate(Date);
  };

  const DtRngeClick = async () => {
    setNewDate(days);
    setShow(false);
    await dispatch(
      getUserPostsAndVotesReport(
        startDate.toLocaleDateString("en-ZA"),
        EndDate.toLocaleDateString("en-ZA")
      )
    );
    await dispatch(
      getNewRegistrationData(
        startDate.toLocaleDateString("en-ZA"),
        EndDate.toLocaleDateString("en-ZA")
      )
    );
  };
  const resetValueClick = async () => {
    await dispatch(getUserPostsAndVotesReport());
    await dispatch(getNewRegistrationData());
  };
  const resetDate = () => {
    setShow(true);
    setNewDate(null);
    setstartDate(null);
    setEndDate(null);
    resetValueClick(null);
  };
  
  if (role && role.roles[0] !== "superadmin") {
    return <Redirect to="/dashboard" />;
  } else {
    return (
      <Grid
        container
       item xs={12}
        style={{ backgroundColor: "rgb(247, 246, 254)", margin: "auto" }}
      >
        <DatePickerInputs
          resetDate={resetDate}
          OnFrmDtChange={OnFrmDtChange}
          OnToDtChange={OnToDtChange}
          DtRngeClick={DtRngeClick}
          startDate={startDate}
          EndDate={EndDate}
          show={show}
        />
        <Grid
          container
         item xs={10}
          style={{ backgroundColor: "rgb(247, 246, 254)", margin: "auto" }}
        >
          <Grid item xs={12}>
            <TextualData
              days={newDate}
              startDate={startDate}
              EndDate={EndDate}
            />{" "}
          </Grid>

          <Grid item xs={12}>
            <PostAndVoteByTopUser />
          </Grid>
          <Grid item xs={12}>
            <PortalTypeAndStats />
          </Grid>
          <Grid item xs={12}>
            <PostAndVotePercentage />
          </Grid>
          <Grid item xs={12}>
            <GenderPercentage />
          </Grid>
        </Grid>
      </Grid>
    );
  }
};
export default ReportPage;
