import React from "react";
import { CSVLink } from "react-csv";
import { useSelector } from "react-redux";
import "./index.css";
const ExportAllUsersData = () => {
  const usersDataExport = useSelector(
    (state) => state.userData.usersDataExport
  );
  const headers = [
    { label: "User Name", key: "username" },
    { label: "Gender ", key: "gender" },
    { label: "Age", key: "birthday" },
    { label: "Email", key: "email" },

    { label: "Selected Categories", key: "categories" },
    { label: "location", key: "location" },
    { label: "User Type", key: "portalType" },
    { label: "Categories Post Count", key: "posts" },
    { label: "Categories Vote Count", key: "votes" },
  ];
  return (
    <>
      <div className="dataExport">
        <CSVLink
          data={usersDataExport}
          headers={headers}
          filename={"All-user-data"}
          style={{ textDecoration: "none" }}
        >
          <div style={{ color: "white", padding: "1px" }}>Export All Data</div>
        </CSVLink>
      </div>
    </>
  );
};
export default ExportAllUsersData;
