import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { ReactComponent as StatsIcon } from "../../../../assets/icons/stats.svg";
import { Card, CardContent } from "@mui/material";
import CountUp from "react-countup";

export const TotalUserWhoVote = () => {
  const userByVotes = useSelector((state) => state.userData.userByVote);
  const portalType = localStorage.getItem("portalType");

  const portalData = userByVotes.find((item) => item.portal_type === portalType);
  const portalCount = parseInt(portalData?.count);

  const otherCounts = userByVotes.filter(
    (item) => item.portal_type !== portalType
  );
  const otherCountSum = otherCounts.reduce(
    (acc, item) => acc + parseInt(item.count),
    0
  );

  const anonymousData = userByVotes.find(
    (item) => item.portal_type === "anonymous"
  );
  const anonymousCount = parseInt(anonymousData?.count);
  const capitalizedPortalType =
  portalType.charAt(0).toUpperCase() + portalType.slice(1);
  return (
    <Card sx={{ maxWidth: 310, boxShadow: "none", margin: "auto" }}>
      <CardContent style={{ backgroundColor: "#f7f6fe", height: 300 }}>
        <div className="experts_heading">
          <center>
            <h3>TOTAL EXPERTS WHO VOTED</h3>
          </center>
          
        </div>
        <br />
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ textAlign: "center", paddingLeft: "20px" }}>
            <span
              style={{
                fontFamily: "Montserrat",
                fontSize: 18,
                fontWeight: 700,
              }}
            >
              {capitalizedPortalType}
            </span>
            <div
              style={{
                fontSize: 28,
                fontFamily: "Montserrat",
                fontWeight: 700,
                marginTop: 7,
              }}
            >
              <StatsIcon />
            </div>
            <div
              style={{
                fontSize: 28,
                fontFamily: "Montserrat",
                fontWeight: 700,
              }}
            >
              <CountUp duration={0.6} end={portalCount} />
            </div>
          </div>

          <div style={{ textAlign: "center", paddingRight: "30px" }}>
            <span
              style={{
                fontFamily: "Montserrat",
                fontSize: 18,
                fontWeight: 700,
              }}
            >
              Community
            </span>
            <div
              style={{
                fontSize: 28,
                fontFamily: "Montserrat",
                fontWeight: 700,
                marginTop: 7,
              }}
            >
              <StatsIcon />
            </div>
            <div
              style={{
                fontSize: 28,
                fontFamily: "Montserrat",
                fontWeight: 700,
              }}
            >
              <CountUp duration={0.6} end={otherCountSum} />
            </div>
          </div>
        </div>

        <div style={{ textAlign: "center", marginTop: "20px" }}>
          <div
            style={{ fontSize: 18, fontFamily: "Montserrat", fontWeight: 700 }}
          >
            Anonymous
          </div>
          <div
            style={{
              fontSize: 28,
              fontFamily: "Montserrat",
              fontWeight: 700,
              marginTop: 10,
            }}
          >
            <StatsIcon />
          </div>
          <div
            style={{ fontSize: 28, fontFamily: "Montserrat", fontWeight: 700 }}
          >
            <CountUp duration={0.6} end={anonymousCount} />
          </div>
        </div>
      </CardContent>
    </Card>
  );
};
