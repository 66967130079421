import React, { useEffect, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import Logout from "../../assets/icons/logoutImg.svg";
import { Box, Grid } from "@mui/material";

import { logoutUser } from "../../actions/auth";
import {
  getCategorieLeaderboardData,
  getCategoriesData,
} from "../../actions/categoriesData";
import {
  getCategoryCount,
  getJunkeeCount,
  getUserCount,
} from "../../actions/overallStats";

import {
  getExportUserData,
  getUserRole,
  getVoteByEthnicity,
  getVoteCountByAgeRange,
  getVoteCountByTime,
} from "../../actions/userData";
import { TabCollection } from "../../components";
import { Redirect } from "react-router-dom";
import { SuperAdminTabs } from "../../components/SuperAdminTabs";
const SuperAdminDashboard = () => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(0);
  const logOut = () => dispatch(logoutUser());
  const { userRole, portalType } = localStorage;
  const [portal, setPortal] = useState("All");
  const setPortalCallback = useCallback(
    (newPortal) => {
      setPortal(newPortal);
    },
    [setPortal]
  );
  const roleAssign = userRole === "admin" ? portalType : "";
  const roleAndPortalAssign = userRole === "admin" ? portalType : portal;
  const capitalizedDashboardAdminName =
    userRole === "superadmin"
      ? "Superadmin"
      : portalType.charAt(0).toUpperCase() + portalType.slice(1);
  useEffect(() => {
    dispatch(getUserRole(JSON.parse(localStorage.getItem("userId"))));
    const currTime = Math.floor(Date.now() / 1000);
    const loggedTime = currTime - localStorage.getItem("loginTime");
    if (loggedTime > 1800) dispatch(logoutUser());
  }, []);

  let dashboardAdminNames = "";
  if (userRole === "admin" && portalType === "winepilot") {
    dashboardAdminNames = "";
  } else {
    dashboardAdminNames = portalType;
  }
  useEffect(() => {
    const fetchData = async () => {
      switch (activeTab) {
        case 0:
          await Promise.all([
            dispatch(getJunkeeCount(roleAndPortalAssign)),
            dispatch(getUserCount(roleAndPortalAssign)),
            dispatch(getCategoryCount(roleAndPortalAssign)),
            await dispatch(getVoteCountByTime(roleAndPortalAssign)),
          ]);
          break;
        case 1:
          await dispatch(getCategoriesData(""));
          break;
        case 2:
          await Promise.all([
            dispatch(getJunkeeCount(roleAssign)),
            dispatch(getExportUserData()),
          ]);
          break;
        case 3:
          await dispatch(getCategorieLeaderboardData(""));
          break;
        case 4:
          await dispatch(getVoteCountByAgeRange(roleAndPortalAssign));
          break;
        case 6:
          await dispatch(getVoteCountByAgeRange(portal));
          break;
        case 7:
          await dispatch(getVoteByEthnicity(roleAndPortalAssign));
          break;
        default:
          break;
      }
    };

    fetchData();
  }, [activeTab, roleAndPortalAssign, roleAssign, dispatch]);

  if (userRole == "admin") {
    return <Redirect to="/dashboard" />;
  } else {
    return (
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2} style={{ marginBottom: 100 }}>
          <Grid item xs={4} md={4}>
            <p className="admin">
              WELCOME, {userRole == "admin" ? "ADMIN!" : "SUPERADMIN!"}
            </p>
          </Grid>
          <Grid item xs={4} md={4}></Grid>
          <Grid item xs={4} md={4}>
            <div className="logout">
              <p>LOGOUT</p>
              <img src={Logout} className="logout_Svg" onClick={logOut} />
            </div>
          </Grid>

          <Grid item xs={12} md={12}>
            <div className="list">
              <p>{capitalizedDashboardAdminName} Dashboard</p>
            </div>
          </Grid>
          <SuperAdminTabs
            portal={portal}
            setPortal={setPortalCallback}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        </Grid>
      </Box>
    );
  }
};

export default SuperAdminDashboard;
