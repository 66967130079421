export const LOGGED_IN = "LOGGED_IN";
export const ALL_EXPERTS = "ALL_EXPERTS";
export const ALL_EXPERTS_SUCCESS = "ALL_EXPERTS_SUCCESS";
export const ALL_EXPERTS_FAILURE = "ALL_EXPERTS_FAILURE";
export const ALL_EXPERTS_NEW_SUCCESS = "ALL_EXPERTS_NEW_SUCCESS";
export const ALL_EXPERTS_NEW_FAILURE = "ALL_EXPERTS_NEW_FAILURE";
export const ALL_WINEPILOT_USER_SUCCESS = "ALL_WINEPILOT_USER_SUCCESS";
export const ALL_WINEPILOT_USER_FAILURE = "ALL_WINEPILOT_USER_FAILURE";
export const ALL_JUNKEECOUNT_NEW_SUCCESS = "ALL_JUNKEECOUNT_NEW_SUCCESS";
export const ALL_JUNKEECOUNT_NEW_FAILURE = "ALL_JUNKEECOUNT_NEW_FAILURE";
export const ALL_CATEGORY_SUCCESS = "ALL_CATEGORY_SUCCESS";
export const ALL_CATEGORY_FAILURE = "ALL_CATEGORY_FAILURE";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";

export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const ALL_USER_SUCCESS = "ALL_USER_SUCCESS";
export const ALL_USER_FAILURE = "ALL_USER_FAILURE";
export const CATEGORIES_DATA_SUCCESS = "CATEGORIES_DATA_SUCCESS";
export const CATEGORIES_DATA_ERROR = "CATEGORIES_DATA_ERROR";
export const ALL_USER_DATA_SUCCESS = "ALL_USER_DATA_SUCCESS";
export const ALL_USER_DATA_FAILURE = "ALL_USER_DATA_FAILURE";
export const ALL_USER_DETAIL_FAILURE = "ALL_USER_DETAIL_FAILURE";
export const ALL_USER_DETAIL_SUCCESS = "ALL_USER_DETAIL_SUCCESS";
export const USER_ROLE_SUCCESS = "USER_ROLE_SUCCESS";
export const USER_ROLE_FAILURE = "USER_ROLE_FAILURE";
export const USER_BAN_SUCCESS = "USER_BAN_SUCCESS";
export const USER_BAN_FAILURE = "USER_BAN_FAILURE";
export const USER_UNBAN_SUCCESS = "USER_UNBAN_SUCCESS";
export const USER_UNBAN_FAILURE = "USER_UNBAN_FAILURE";
export const ALL_USER_DATA_EXPORT_SUCCESS = "ALL_USER_DATA_EXPORT_SUCCESS";
export const ALL_USER_DATA_EXPORT_FAILURE = "ALL_USER_DATA_EXPORT_FAILURE";
export const ALL_USER_DATA_SEARCH_SUCCESS = "ALL_USER_DATA_SEARCH_SUCCESS";
export const ALL_USER_DATA_SEARCH_FAILURE = "ALL_USER_DATA_SEARCH_FAILURE";
export const TOTAL_USER_REPORT_SUCCESS = "TOTAL_USER_REPORT_SUCCESS";
export const TOTAL_USER_REPORT_FAILURE = "TOTAL_USER_REPORT_FAILURE";
export const TOTAL_USER_POSTS_REPORT_SUCCESS =
  "TOTAL_USER_POSTS_REPORT_SUCCESS";
export const TOTAL_USER_POSTS_REPORT_FAILURE =
  "TOTAL_USER_POSTS_REPORT_FAILURE";
export const TOTAL_USER_POSTS_VOTES_DETAILS_DATE_SUCCESS =
  "TOTAL_USER_POSTS_VOTES_DETAILS_DATE_SUCCESS";
export const TOTAL_USER_POSTS_VOTES_DETAILS_DATE_FAILURE =
  "TOTAL_USER_POSTS_VOTES_DETAILS_DATE_FAILURE";
export const TOTAL_NEW_REGISTRATION_SUCCESS = "TOTAL_NEW_REGISTRATION_SUCCESS";
export const TOTAL_NEW_REGISTRATION_FAILURE = "TOTAL_NEW_REGISTRATION_FAILURE";
export const GENDER_COUNT_SUCCESS = "GENDER_COUNT_SUCCESS";
export const GENDER_COUNT_FAILURE = "GENDER_COUNT_FAILURE";
export const VOTEBY_PORTALTYPE_SUCCESS = "VOTEBY_PORTALTYPE_SUCCESS";
export const VOTEBY_PORTALTYPE_FAILURE = "VOTEBY_PORTALTYPE_FAILURE";
export const VOTEBY_PORTALTYPE_COUNT_SUCCESS =
  "VOTEBY_PORTALTYPE_COUNT_SUCCESS";
export const VOTEBY_PORTALTYPE_COUNT_FAILURE =
  "VOTEBY_PORTALTYPE_COUNT_FAILURE";
export const ALL_CATEGORY_LEADERBOARD_SUCCESS =
  "ALL_CATEGORY_LEADERBOARD_SUCCESS";
export const ALL_CATEGORY_LEADERBOARD_FAILURE =
  "ALL_CATEGORY_LEADERBOARD_FAILURE";
export const All_VOTE_AND_POST_USERS_SUCCESS =
  "All_VOTE_AND_POST_USERS_SUCCESS";
export const All_VOTE_AND_POST_USERS_FAILURE =
  "All_VOTE_AND_POST_USERS_FAILURE";
export const ALL_VOTE_COUNT_BY_AGE_RANGE_SUCCESS =
  "ALL_VOTE_COUNT_BY_AGE_RANGE_SUCCESS";
export const ALL_VOTE_COUNT_BY_AGE_RANGE_FAILURE =
  "ALL_VOTE_COUNT_BY_AGE_RANGE_FAILURE";
export const ALL_VOTE_COUNT_BY_TIME_SUCCESS = "ALL_VOTE_COUNT_BY_TIME_SUCCESS";
export const ALL_VOTE_COUNT_BY_TIME_FAILURE = "ALL_VOTE_COUNT_BY_TIME_FAILURE";
export const ALL_VOTE_BY_ETHNICITY_SUCCESS = "ALL_VOTE_BY_ETHNICITY_SUCCESS";
export const ALL_VOTE_BY_ETHNICITY_FAILURE = "ALL_VOTE_BY_ETHNICITY_FAILURE";
export const USER_BY_VOTE_SUCCESS = "USER_BY_VOTE_SUCCESS";
export const USER_BY_VOTE_FAILURE = "USER_BY_VOTE_FAILURE";
export const TOP_CATEGORIES_BYVOTE_SUCCESS = "TOP_CATEGORIES_BYVOTE_SUCCESS";
export const TOP_CATEGORIES_BYVOTE_FAILURE = "TOP_CATEGORIES_BYVOTE_FAILURE";
export const QUESTION_VOTECOUNT_BY_AGE_SUCCESS =
  "QUESTION_VOTECOUNT_BY_AGE_SUCCESS";
export const QUESTION_VOTECOUNT_BY_AGE_FAILURE =
  "QUESTION_VOTECOUNT_BY_AGE_FAILURE";

export const QUESTION_VOTECOUNT_BY_EHNICITY_SUCCESS =
  "QUESTION_VOTECOUNT_BY_EHNICITY_SUCCESS";
export const QUESTION_VOTECOUNT_BY_EHNICITY_FAILURE =
  "QUESTION_VOTECOUNT_BY_EHNICITY_FAILURE";

export const CREATE_POST_TAG_SUCCESS = "CREATE_POST_TAG_SUCCESS";
export const CREATE_POST_TAG_FAILURE = "CREATE_POST_TAG_FAILURE";

export const GET_POST_FOR_TAG_SUCCESS = "GET_POST_FOR_TAG_SUCCESS";
export const GET_POST_FOR_TAG_FAILURE = "GET_POST_FOR_TAG_FAILURE";

export const GET_POST_AND_TAG_DATA_SUCCESS = "GET_POST_AND_TAG_DATA_SUCCESS";
export const GET_POST_AND_TAG_DATA_FAILURE = "GET_POST_AND_TAG_DATA_FAILURE";

export const GET_SEARCH_POST_FOR_TAG_SUCCESS =
  "GET_SEARCH_POST_FOR_TAG_SUCCESS";
export const GET_SEARCH_POST_FOR_TAG_FAILURE =
  "GET_SEARCH_POST_FOR_TAG_FAILURE";

export const GET_COUNT_FOR_TAG_SUCCESS = "GET_COUNT_FOR_TAG_SUCCESS";
export const GET_COUNT_FOR_TAG_FAILURE = "GET_COUNT_FOR_TAG_FAILURE";

export const GET_SEARCH_RESULT_FOR_TAG_SUCCESS =
  "GET_SEARCH_RESULT_FOR_TAG_SUCCESS";
export const GET_SEARCH_RESULT_FOR_TAG_FAILURE =
  "GET_SEARCH_RESULT_FOR_TAG_FAILURE";
export const GET_CLOSED_POST_COUNT_SUCCESS = "GET_CLOSED_POST_COUNT_SUCCESS";
export const GET_CLOSED_POST_COUNT_FAILURE = "GET_CLOSED_POST_COUNT_FAILURE";

export const UPDATE_ANONYMOUSVOTE_PORTAL_STATUS_SUCCESS =
  "UPDATE_ANONYMOUSVOTE_PORTAL_STATUS_SUCCESS";
export const UPDATE_ANONYMOUSVOTE_PORTAL_STATUS_FAILURE =
  "UPDATE_ANONYMOUSVOTE_PORTAL_STATUS_FAILURE";
  export const GET_ANONYMOUS_ACTION_LIST_SUCCESS =
  "GET_ANONYMOUS_ACTION_LIST_SUCCESS";
export const GET_ANONYMOUS_ACTION_LIST_FAILURE =
  "GET_ANONYMOUS_ACTION_LIST_FAILURE";
