import * as React from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Grid } from "@mui/material";
import DatePicker from "react-datepicker";
import addDays from "date-fns/add";
const DatePickerInputs = ({
  resetDate,
  OnFrmDtChange,
  OnToDtChange,
  DtRngeClick,
  startDate,
  EndDate,
  show,
}) => {
  return (
    <>
      <style>
        {`.date-picker input {
           width: 110px;  
           height:28px;
           border:2px solid #773c8f;
           margin-left:20px

       }`}
      </style>
      <Grid item xs={11}>
        <div className="datepicker">
          <div className="date-picker-alignment">
            <DatePicker
              wrapperClassName="date-picker"
              placeholderText="From"
              id="fromDt"
              CssclassName="txtdob_hs"
              dateFormat="yyyy-MM-dd"
              selected={startDate}
              onChange={OnFrmDtChange}
              maxDate={new Date()}
              required
            />
            <DatePicker
              id="toDt"
              wrapperClassName="date-picker"
              placeholderText="To"
              CssclassName="txtdob_hs"
              dateFormat="yyyy-MM-dd"
              minDate={new Date(startDate)}
              selected={EndDate}
              onChange={OnToDtChange}
              maxDate={new Date()}
              disabled={!startDate}
              required
            />
            {show ? (
              <button
                className="submit-btn-for-dates"
                onClick={() => DtRngeClick()}
                disabled={!EndDate}
              >
                <div className="submit-date-text">Submit</div>
              </button>
            ) : (
              <button className="reset-btn-for-dates" onClick={resetDate}>
                <div className="reset-date-text">Reset</div>
              </button>
            )}
          </div>
        </div>
      </Grid>{" "}
    </>
  );
};

export default DatePickerInputs;
