import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTopCategoriesByVote } from "../../../../actions/userData";
import {
  FormControl,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import Chart from "react-apexcharts";

export const Graph = () => {
  const [filterOption, setFilterOption] = useState("All");
  const dispatch = useDispatch();
  const portalVal = localStorage.getItem("portalType");
  const handleChangePortal = (event, newValue) => {
    if (newValue !== null) {
      dispatch(getTopCategoriesByVote(portalVal, newValue));
      setFilterOption(newValue);
    }
  };

  useEffect(() => {
    dispatch(getTopCategoriesByVote(portalVal, filterOption));
  }, []);

  const topCategoryByVote =
    useSelector((state) => state?.userData?.topCategoryByVote) || [];

  const [chartOptions, setChartOptions] = useState({
    chart: {
      id: "basic-bar",
    },
    xaxis: {
      title: {
        text: "Category",
        style: {
          fontSize: 18,
        },
      },
      categories: [],
    },
    plotOptions: {
      bar: {
        horizontal: false,
        dataLabels: {
          position: "top",
        },
      },
    },
    yaxis: {
      title: {
        text: "Count",
        style: {
          fontSize: 18,
        },
      },
      labels: {
        formatter: function (val) {
          return val;
        },
      },
    },
    dataLabels: {
      enabled: true,
      offsetY: -20,
      style: {
        fontSize: "12px",
        colors: ["#304758"],
      },
    },
  });
  const filterOptionValues = {
    question: "Post count",
    answer: "Vote count",
    All: "Posts & Votes",
  };

  const filterOptionValue = filterOptionValues[filterOption];
  const [chartSeries, setChartSeries] = useState([
    {
      name: "Votes",
      data: [],
    },
  ]);

  useEffect(() => {
    const categories = topCategoryByVote
      ?.slice(0, 10)
      .map((category) => category.icon + category.title);
    const votes = topCategoryByVote
      .slice(0, 10)
      .map((category) => category.count);
    setChartOptions((options) => ({
      ...options,
      xaxis: {
        ...options.xaxis,
        categories: categories,
      },
    }));
    setChartSeries([{ name: filterOptionValue, data: votes }]);
  }, [topCategoryByVote]);

  const headings = {
    All: " Posts & Votes",
    answer: "Votes",
    question: "Posts",
  };

  const chartHeading = headings[filterOption] || "";

  return (
    <>
      <Grid item xs={11} sx={{ marginTop: 5 }}>
        <p
          style={{
            fontSize: 18,
            fontFamily: "Montserrat",
            fontWeight: 500,
            textAlign: "center",
          }}
        >
          Top 10 categories by {chartHeading}
        </p>
        <Grid container justifyContent="flex-end">
          <Grid item xs={10} md={2.2} sx={{ margin: "35px" }}>
            <FormControl sx={{ width: 180 }}>
              <ToggleButtonGroup
                value={filterOption}
                exclusive
                onChange={handleChangePortal}
                aria-label="FilterOption"
              >
                <ToggleButton
                  value="All"
                  aria-label="All"
                  style={{
                    backgroundColor: filterOption === "All" ? "#7C10A7" : "",
                    color: filterOption === "All" ? "white" : "#767676",
                  }}
                >
                  Both
                </ToggleButton>
                <ToggleButton
                  value="answer"
                  aria-label="Votes"
                  style={{
                    backgroundColor: filterOption === "answer" ? "#7C10A7" : "",
                    color: filterOption === "answer" ? "white" : "#767676",
                  }}
                >
                  Votes
                </ToggleButton>
                <ToggleButton
                  value="question"
                  aria-label="Question"
                  style={{
                    backgroundColor:
                      filterOption === "question" ? "#7C10A7" : "",
                    color: filterOption === "question" ? "white" : "#767676",
                  }}
                >
                  Posts
                </ToggleButton>
              </ToggleButtonGroup>
            </FormControl>
          </Grid>
        </Grid>

        <Chart
          options={chartOptions}
          series={chartSeries}
          type="bar"
          height={450}
        />
      </Grid>
    </>
  );
};
