import React, { useState, useEffect } from "react";
import { ReactComponent as StatsIcon } from "../../../assets/icons/stats.svg";
import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import {
  StatsCard,
  TopCategory,
  VoteByTimeTrend,
  TotalUserWhoVote
} from "../Cards";

const OverAllStats = ({ setActiveTab }) => {
  const expertCount = useSelector((state) => state.overallStats.junkeeCount);
  const [junkeeCount, setJunkeeCount] = useState();

  useEffect(() => {
    if (expertCount !== "undefined" && expertCount !== "") {
      setJunkeeCount(expertCount[0].totalexper);
    }
  }, [expertCount]);

  const handleViewAllUsers = () => {
    if (typeof setActiveTab === "function") {
      setActiveTab(2);
    }
  };

  const portalType = localStorage.getItem("portalType");
  const showTypes = {
    type1: true,
    type2: true,
    type3: true
  };
  const capitalizedPortalType = portalType.toUpperCase();
  const totalExpert = {
    expertHeading: `TOTAL ${capitalizedPortalType} EXPERTS`,
    icon: <StatsIcon />,
    junkeeCounts: junkeeCount,
    expertButtonLabel: "View All Users",
    handleExpertButtonClick: handleViewAllUsers
  };

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      alignItems="center"
      style={{ maxWidth: 1400, margin: "100px auto" }}
    >
      <Grid item xs={12} md={3}>
        <StatsCard data={totalExpert} showTypes={showTypes} />
      </Grid>
      <Grid item xs={12} md={3}>
        <TopCategory setActiveTab={setActiveTab} showTypes2={showTypes} />
      </Grid>
      <Grid item xs={12} md={3}>
        <TotalUserWhoVote />
      </Grid>
      <Grid item xs={12} md={3}>
        <VoteByTimeTrend />
      </Grid>
    </Grid>
  );
};

export default OverAllStats;
