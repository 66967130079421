import React, { Suspense } from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";

import RouterHandle from "../../router";


class App extends React.PureComponent {
  render() {
    
    return (
      <div>
        <Suspense fallback={<div>Loading... </div>}>
          <Router>
            <RouterHandle />
          </Router>

        </Suspense>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps)(App);
