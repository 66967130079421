import {
  CATEGORIES_DATA_SUCCESS,
  ALL_CATEGORY_LEADERBOARD_SUCCESS,
} from "../constants";

export function categoriesData(
  state = {
    isFetching: false,
    categoryData: {},
    categoryLeaderboardData: "",
    errorMessage: "",
    // isAuthenticated: authenticated,
  },
  action
) {
  switch (action.type) {
    case CATEGORIES_DATA_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        categoryData: action.payload,
        errorMessage: "",
      });
    case ALL_CATEGORY_LEADERBOARD_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        categoryLeaderboardData: action.payload,
        errorMessage: "",
      });

    default:
      return state;
  }
}
