import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from "react";
import {
  Box,
  Grid,
  TextField,
  Typography,
  TableContainer,
  Paper,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Button,
  InputAdornment,
  Pagination,
  Link,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { QuestionTableRow } from "../QuestionTableRow";
import { Add, Delete, Search, Close } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import {
  createPostTag,
  deletePostByTag,
  getPostForTag,
  getSearchDataForPost,
} from "../../../../actions/userData";
import ErrorMessage from "../ErrorMessage";

export const PostTable = ({
  newTag,
  getLiveQuestions,
  handleTagAdd,
  tags,
  handleTagRemove,
  handleTagInputChange,
  voteTotalCount,
  handleChange,
  voteCount,
  setTags,
  votePage,
  postClosed,

  selectedQuestions,
  setSelectedQuestions,
}) => {
  const dispatch = useDispatch();
  const limit = useRef(20);
  const [open, setOpen] = useState(false);

  const portalType = localStorage.getItem("portalType");
  const role = localStorage.getItem("userRole");
  const [searchQuery, setSearchQuery] = useState("");
  const searchedVal = useSelector((state) => state.userData?.getSearchData);
  const [selectedPostId, setSelectedPostId] = useState(null);

  const postStatus = false;
  const username = localStorage.getItem("name");

  useEffect(() => {
    if (searchQuery !== "") {
      dispatch(
        getSearchDataForPost(portalType, searchQuery, postStatus, username)
      );
    }
  }, [searchQuery, portalType]);
  const handleClearSearch = () => {
    setSearchQuery("");
  };

  const handleDelete = useCallback((postId) => {
    setSelectedPostId(postId);
  }, []);

  const handleDeleteConfirmation = useCallback(async () => {
    await deletePostByTag(selectedPostId)();
    setSelectedPostId(null);
    setTags((tags) => tags.filter((tag) => tag.id !== selectedPostId));
    dispatch(
      getSearchDataForPost(portalType, searchQuery, postStatus, username)
    );
    dispatch(
      getPostForTag(
        role === "admin" ? portalType : "",
        limit.current,
        votePage,
        postClosed,
        username
      )
    );
    toast.success("Tag removed successfully");
  }, [
    selectedPostId,
    dispatch,
    portalType,
    tags,
    setTags,
    role,
    limit,
    votePage,
    postClosed,
  ]);

  const handleDeleteCancel = useCallback(() => {
    setSelectedPostId(null);
  }, []);

  const handleSave = useCallback(async () => {
    if (tags.length > 0 && selectedQuestions.length > 0) {
      try {
        await createPostTag({
          portal_type: "winepilot",
          tag: tags,
          postid: selectedQuestions.map((id) => id.toString()),
          status: 1,
        })();
        // Show success toast notification
        toast.success("Tag  created successfully");
        setTags([]);
        setSelectedQuestions("");
        dispatch(
          getSearchDataForPost(portalType, searchQuery, postStatus, username)
        );
        dispatch(
          getPostForTag(
            role === "admin" ? portalType : "",
            limit.current,
            votePage,
            postClosed,
            username
          )
        );
      } catch (error) {
        console.error(error);
        setOpen(true);
      }
    } else {
      setOpen(true);
    }
  }, [selectedQuestions, tags]);
  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);
  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Dialog
        open={!!selectedPostId}
        onClose={handleDeleteCancel}
        sx={{ animation: `${!!selectedPostId ? "shake 0.5s" : ""}` }}
      >
        <DialogTitle style={{ color: "red", fontFamily: "Montserrat" }}>
          Remove Tag
        </DialogTitle>
        <DialogContent>
          <DialogContentText style={{ fontSize: 20, fontFamily: "Montserrat" }}>
            Are you sure you want to remove this tag?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleDeleteCancel}
            style={{
              backgroundColor: "#7C10A7",
              borderRadius: 5,
              margin: "auto",
              color: "white",

              fontFamily: "Montserrat",
              fontSize: 12,
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleDeleteConfirmation}
            style={{
              backgroundColor: "#7C10A7",
              borderRadius: 5,
              margin: "auto",
              color: "white",
              fontFamily: "Montserrat",
              fontSize: 12,
            }}
          >
            Remove
          </Button>
        </DialogActions>
      </Dialog>
      <Typography
        style={{
          margin: "50px auto",
          fontSize: 22,
          textAlign: "center",
        }}
      >
        Create Post Tag map
      </Typography>
      <Grid
        container
        spacing={2}
        alignItems="center"
        margin="auto"
        justifyContent="center"
      >
        <Grid item xs={12} md={6}>
          <Box display="flex" alignItems="center">
            <TextField
              label="Enter tag here"
              variant="outlined"
              size="medium"
              fullWidth
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderColor: "#7C10A7",
                  "&:hover fieldset": {
                    borderColor: "#7C10A7",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#7C10A7",
                  },
                },
                "& .MuiInputLabel-root": {
                  "&.Mui-focused": {
                    color: "#7C10A7",
                  },
                },
              }}
              style={{ margin: 10 }}
              value={newTag}
              onChange={handleTagInputChange}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  handleTagAdd();
                }
              }}
              error={tags.includes(newTag.trim())}
              helperText={
                tags.includes(newTag.trim()) ? "Tag already added" : ""
              }
            />

            <Box display="flex" alignItems="center" justifyContent="center">
              <Button
                variant="text"
                startIcon={<Add style={{ fontSize: 40, marginLeft: "8px" }} />}
                style={{
                  backgroundColor: "#7C10A7",
                  color: "white",
                  padding: 7,
                  position: tags.includes(newTag.trim())
                    ? "relative"
                    : "static",
                  top: tags.includes(newTag.trim()) ? "-10px" : "0px",
                }}
                onClick={() => handleTagAdd()}
              ></Button>
            </Box>
          </Box>

          <Box display="flex" flexDirection="column">
            {tags.length > 0 && (
              <Box display="flex" flexWrap="wrap">
                {tags.map((tag, index) => (
                  <Box
                    key={tag}
                    className="animated-chip"
                    sx={{
                      backgroundColor: "#7C10A7",
                      color: "white",
                      borderRadius: 5,
                      display: "flex",
                      alignItems: "center",
                      margin: "5px",
                      fontFamily: "Montserrat",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "white",
                        alignItems: "center",
                        fontFamily: "Montserrat",
                        padding: "5px 1px 5px 15px",
                      }}
                    >
                      {tag}
                    </Typography>
                    <Button
                      variant="text"
                      startIcon={<Delete />}
                      style={{ color: "white", padding: 10 }}
                      onClick={() => handleTagRemove(index)}
                    ></Button>
                  </Box>
                ))}
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
      <Box mt={4}>
        <Box display="flex" alignItems="right" justifyContent="right">
          <TextField
            label="Search post"
            id="outlined-size-small"
            sx={{
              "& .MuiOutlinedInput-root": {
                borderColor: "#7C10A7",
                "&:hover fieldset": {
                  borderColor: "#7C10A7",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#7C10A7",
                },
              },
              "& .MuiInputLabel-root": {
                "&.Mui-focused": {
                  color: "#7C10A7",
                },
              },
            }}
            size="small"
            style={{ margin: 10 }}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                setSearchQuery(event.target.value);
              }
            }}
            InputProps={{
              endAdornment: searchQuery ? (
                <InputAdornment position="end">
                  <Close
                    style={{ color: "rgb(124, 16, 167)", cursor: "pointer" }}
                    onClick={handleClearSearch}
                  />
                </InputAdornment>
              ) : (
                <InputAdornment position="end">
                  <Search style={{ color: "rgb(124, 16, 167)" }} />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Typography style={{ margin: "30px 20px", fontSize: 24 }}>
          Please select the post:
        </Typography>
        <TableContainer component={Paper} elevation={0}>
          <Table
            sx={{ minWidth: 650, margin: "20px ,auto" }}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    paddingLeft: 70,
                    fontWeight: 700,
                    fontSize: 18,
                    fontFamily: "Montserrat",
                  }}
                >
                  Post{" "}
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: 700,
                    fontSize: 18,
                    fontFamily: "Montserrat",
                  }}
                >
                  Post Status
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {searchQuery && searchedVal.length > 0 ? (
                searchedVal &&
                searchedVal?.map((item, index) => (
                  <QuestionTableRow
                    key={item.id}
                    question={item}
                    selectedQuestions={selectedQuestions}
                    setSelectedQuestions={setSelectedQuestions}
                    handleDelete={handleDelete}
                    setTags={setTags}
                    tags={tags}
                  />
                ))
              ) : (
                <React.Fragment>
                  {searchQuery && searchedVal && searchedVal.length === 0 && (
                    <TableRow>
                      <TableCell
                        sx={{ fontSize: 22, padding: "100px" }}
                        align="center"
                        colSpan={4}
                      >
                        No results found.
                      </TableCell>
                    </TableRow>
                  )}
                  {!searchQuery &&
                    getLiveQuestions &&
                    getLiveQuestions.map((item, index) => (
                      <QuestionTableRow
                        key={item.id}
                        question={item}
                        selectedQuestions={selectedQuestions}
                        setSelectedQuestions={setSelectedQuestions}
                        handleDelete={handleDelete}
                        setTags={setTags}
                        tags={tags}
                      />
                    ))}

                  {!searchQuery &&
                    getLiveQuestions &&
                    getLiveQuestions.length == 0 && (
                      <TableRow>
                        <TableCell
                          sx={{ fontSize: 22, padding: "100px" }}
                          align="center"
                          colSpan={4}
                        >
                          No data found.
                        </TableCell>
                      </TableRow>
                    )}
                </React.Fragment>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box sx={{ margin: "40px auto" }}>
        {searchQuery
          ? null
          : voteCount >= 20 && (
              <Pagination
                count={voteTotalCount}
                onChange={handleChange}
                color="secondary"
                shape="rounded"
                style={{ margin: "auto" }}
              />
            )}
      </Box>

      {getLiveQuestions.length > 0 && (
        <Box sx={{ textAlign: "center", margin: "20px auto" }}>
          <Button
            style={{
              backgroundColor: "#7C10A7",
              borderRadius: 5,
              padding: "8px",
              fontFamily: "Montserrat",
              fontSize: 18,
              width: "100px",
            }}
            variant="contained"
            onClick={handleSave}
          >
            Save
          </Button>
        </Box>
      )}
      <ErrorMessage open={open} handleClose={handleClose} />
    </>
  );
};
