import React, { useMemo } from "react";
import DefaultImage from "../../../../assets/icons/defaultImage.svg";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { StatsCard } from "../StatsCard";
import "./index.css";
export const TopUser = ({ showTypes3, setActiveTab }) => {
  const userArr = useSelector((state) => state?.overallStats?.topUsers);

  const handleViewAllUsers = () => {
    setActiveTab(2);
  };

  const usersImage = useMemo(
    () =>
      userArr?.map((user, index) => (
        <img
          key={index}
          src={
            user?.image == null
              ? DefaultImage
              : user?.image.includes("googleusercontent")
              ? user?.image
              : `${process.env.REACT_APP_IMAGE_URL}` + user?.image
          }
          alt="userprofile"
          className="user-profile"
        />
      )),
    [userArr]
  );

  const usersName = useMemo(
    () =>
      userArr?.map((user, index) => <span key={index}>{user?.username}</span>),
    [userArr]
  );

  const topUsers = {
    heading: "TOP 5 EXPERTS",
    usersimage: usersImage,
    usersname: usersName,
    userButtonLabel: "View All Users",
    handleUserButtonClick: handleViewAllUsers,
  };

  return <StatsCard data={topUsers} showTypes3={showTypes3} />;
};
