import React, { useEffect, useState, useMemo } from "react";
import { Tabs, Tab, Box, Grid, Divider } from "@mui/material";
import ReportPage from "../../pages/ReportPage";
import SuperAdminSelectPostForWebApp from "../SuperAdmin/SuperAdminSelectPostForWebApp";
import OverAllStats from "../SuperAdmin/OverAllStats";
import CategoryList from "../SuperAdmin/CategoryList";
import CategoryStats from "../SuperAdmin/CategoryStats";
import UsersDetailList from "../SuperAdmin/UsersDetailList";
import AgeRange from "../SuperAdmin/VoteBasesOn/AgeRange";
import Ethnicity from "../SuperAdmin/VoteBasesOn/Ethnicity";
import PortalType from "../SuperAdmin/VoteBasesOn/PortalType";

export const SuperAdminTabs = ({
  portal,
  setPortal,
  activeTab,

  setActiveTab,
}) => {
  const handleTabChange = (event, newValue) => setActiveTab(newValue);
  const { userRole, portalType } = localStorage;
  useEffect(() => {
    setPortal("All");
  }, [activeTab]);

  const tabs = useMemo(() => {
    return [
      {
        label: "Overall Stats",
        divider: true,
        component: (
          <OverAllStats
            setActiveTab={setActiveTab}
            portal={portal}
            setPortal={setPortal}
          />
        ),
      },
      { label: "All Categories", divider: true, component: <CategoryList /> },
      {
        label: "All Users",
        divider: true,
        component: <UsersDetailList />,
      },
      {
        label: "Category Stats",
        divider: true,
        component: <CategoryStats />,
      },
      {
        label: "Reports",
        divider: true,
        component: <ReportPage />,
      },
      {
        label: "Vote (By Portal Type)",
        divider: true,
        component: <PortalType />,
      },
      {
        label: "Vote (By Age Range)",
        divider: true,
        component: <AgeRange portal={portal} setPortal={setPortal} />,
      },
      {
        label: "Vote (By Ethnicity)",
        divider: true,
        component: <Ethnicity portal={portal} setPortal={setPortal} />,
      },

      {
        label: "Post tag map",
        divider: false,
        component: (
          <SuperAdminSelectPostForWebApp
            portal={portal}
            setPortal={setPortal}
          />
        ),
      },
    ].filter((tab) => tab);
  }, [portal, portalType, setActiveTab, setPortal, userRole]);

  const tabLabels = tabs;

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        item
        md={12}
      >
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          style={{ overflow: "inherit" }}
          variant="scrollable"
          scrollButtons="auto"
          indicatorColor="none"
        >
          {tabLabels.map((tab, index) => (
            <Tab
              key={index}
              label={
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div>{tab.label}</div>
                  {tab.divider && (
                    <Divider
                      orientation="vertical"
                      sx={{
                        marginLeft: 2,
                        border: "1px solid",
                        height: "22px",
                        backgroundColor: "#767676",
                        color: "#767676",
                      }}
                    />
                  )}
                </Box>
              }
              sx={{
                color: "#767676 !important",
                fontWeight: 700,
                fontSize: 13,
                padding: "10px !important",

                "&.Mui-selected": {
                  fontSize: 14,
                  color: "#7C10A7  !important",
                },
              }}
            />
          ))}
        </Tabs>
      </Grid>
      {tabLabels[activeTab] && tabLabels[activeTab].component}
    </>
  );
};
