import React, { useEffect, useRef, useState } from "react";
import { Pagination } from "@mui/material";
import DefaultImage from "../../../../assets/icons/defaultImage.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserVoteDataByPortalType,
  getVoteDataByPortalTypeCount,
} from "../../../../actions/userData";
import DataTable from "../../DataTable";
import { DataFilterByPortal } from "../../DataFilterByPortal";

const PortalType = () => {
  const dispatch = useDispatch();
  const [portal, setPortal] = useState("All");
  const limitRef = useRef(20);
  const [votePage, setVotePage] = useState(1);

  const userVotesByPortalTypes = useSelector(
    (state) => state.userData.userVotesByPortalType
  );
  const totalVotesByPortalTypeCount = useSelector(
    (state) => state.userData.votesByPortalTypeCount
  );

  const dataByPortalType = userVotesByPortalTypes.postbyUserportal;

  useEffect(() => {
    dispatch(getUserVoteDataByPortalType(portal, votePage));
    dispatch(getVoteDataByPortalTypeCount(portal));
  }, [portal, votePage]);

  const voteCount = totalVotesByPortalTypeCount[0]?.totalpost || "";
  const voteTotalCount = Math.ceil(Number(voteCount) / limitRef.current);

  const handleChange = (event, value) => {
    setVotePage(value);
  };

  const tableColumns = [
    { key: "username", label: "User who posted" },
    { key: "question", label: "Post" },
    { key: "answer", label: "Decided Answer" },
    { key: "voteCounts", label: "Vote (By Portal Type)" },
    { key: "totalVotes", label: "Total" },
  ];

  const tableData = dataByPortalType?.map((row, id) => {
    const username = (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginLeft: 67,
        }}
      >
        <img
          src={row[0].image ?? DefaultImage}
          width={40}
          height={40}
          style={{ borderRadius: "8px", marginRight: "8px" }}
        />
        <div>{row[0].username}</div>
      </div>
    );

    const voteCounts = row[1]
      .filter((item) => item.votecount !== "0")
      .map((item) => (
        <div key={item.portal_type}>
          {item.portal_type}: {item.votecount}
        </div>
      ));

    const totalVotes = row[1]
      .map((item) => parseInt(item.votecount))
      .reduce((acc, count) => acc + count, 0);

    return {
      id,
      username,
      question: row[0].question,
      answer: row[0].win_answer === "N/A" ? "Undecided" : row[0].win_answer,
      voteCounts: voteCounts.length > 0 ? voteCounts : <div>No votes</div>,
      totalVotes,
    };
  }) || [];

  return (
    <>
      <DataFilterByPortal portal={portal} setPortal={setPortal} />
      <DataTable data={tableData} columns={tableColumns} />
      <Pagination
        count={voteTotalCount}
        onChange={handleChange}
        color="secondary"
        shape="rounded"
        style={{ margin: "auto" }}
      />
    </>
  );
};

export default PortalType;
