import {
  ALL_EXPERTS_SUCCESS,
  ALL_CATEGORY_SUCCESS,
  ALL_USER_SUCCESS,
  ALL_EXPERTS_NEW_SUCCESS,
  ALL_WINEPILOT_USER_SUCCESS,
} from "../constants";
import { ALL_JUNKEECOUNT_NEW_SUCCESS } from "../constants/index";

export function overallStats(
  state = {
    isFetching: false,
    expertCount: "",
    winePilotCount: "",
    newexpertCount: "",
    topCategories: [],
    topUsers: [],
    junkeeCount: '',
    errorMessage: "",
    // isAuthenticated: authenticated,
  },
  action
) {
  switch (action.type) {
    case ALL_EXPERTS_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        expertCount: action.payload,
        errorMessage: "",
      });
    case ALL_WINEPILOT_USER_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        winePilotCount: action.payload,
        errorMessage: "",
      });
    case ALL_EXPERTS_NEW_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        newexpertCount: action.payload,
        errorMessage: "",
      });
    case ALL_CATEGORY_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        topCategories: action.payload,
        errorMessage: "",
      });
    case ALL_USER_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        topUsers: action.payload,
        errorMessage: "",
      });

    case ALL_JUNKEECOUNT_NEW_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        junkeeCount: action.payload,
        errorMessage: "",
      });
    default:
      return state;
  }
}
