import React from "react";
import "./index.css";
import Card from "@mui/material/Card";
import Chart from "react-apexcharts";
import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import UserCountByVoteAndPost from "./UserCountByVoteAndPost/index";
const GenderPercentage = () => {
  const genderData = useSelector((state) => state.totalUserReport.genderData);
  const totalPostAndVoteByUser = useSelector(
    (state) => state.totalUserReport.postAndVoteByUser
  );
  const totalCountByPostAndVote = [];
  if (totalPostAndVoteByUser != "" && totalCountByPostAndVote.length <= 0) {
    totalCountByPostAndVote.push({
      sets: ["posts"],
      value: Number(totalPostAndVoteByUser.userWhoPosts[0]),
      name: ["users post"],
      color: "#f02626",
      opacity: 1,
    });

    totalCountByPostAndVote.push({
      sets: ["votes"],
      value: Number(totalPostAndVoteByUser.userWhoVotes[0]),
      name: ["users vote"],
      color: "#277BC0",
      opacity: 1,
    });
    if (totalCountByPostAndVote != "" && totalCountByPostAndVote != undefined) {
      totalCountByPostAndVote.push({
        sets: ["posts", "votes"],
        value: Number(totalPostAndVoteByUser.userWhoPostsAndVotes[0]),
        name: ["posts & votes "],
        color: "#FFB200",
        opacity: 1,
      });
    }
  }
  const totalUserCounts = totalPostAndVoteByUser.totalUsers;
  const series = [];
  var male = 0;
  var female = 0;
  var notfilled = 0;

  {
    genderData.length > 0 &&
      genderData.map((item) => {
        if (item[0].totalfemale != undefined && item[0].totalfemale != "") {
          series.push(item[0].totalfemale);
        }
        if (item[0].totalmale != undefined && item[0].totalmale != "") {
          series.push(item[0].totalmale);
        }
        if (item[0].datanotfilled != undefined && item[0].datanotfilled != "") {
          series.push(item[0].datanotfilled);
        }
      });
  }
  male = series[1];
  female = series[0];
  notfilled = series[2];
  const total = parseInt(male) + parseInt(female) +  parseInt(notfilled);
  const MalePer = Math.round((parseInt(male) / total) * 100);
  const FemalePer = Math.round((parseInt(female) / total) * 100);
  const NotfilledPer = Math.round((parseInt(notfilled) / total) * 100);

  const final = [];
  final.push(MalePer);
  final.push(FemalePer);
  final.push(NotfilledPer);
  const seriesGenderData = [{ data: final, name: "Gender " }];
  const genderPercentageData = {
    labels: ["Male %", "Female  %", "Data not available  %"],
    plotOptions: {
      bar: {
        distributed: true,
        dataLabels: {
          position: "center",
        },
      },
    },
    colors: ["#f02626", "#277BC0", "#FFB200"],
  };

  return (
    <Grid container spacing={2}>
      <Grid md={6} xs={12}>
        <Card sx={{ m: 4, boxShadow: 2 }} style={{ width: "93%" }}>
          <p className="top-user-heading">Gender %</p>
          <div className="gender-percentage-charts">
            {" "}
            <Chart
              type="pie"
              width={520}
              series={seriesGenderData[0].data}
              options={genderPercentageData}
            ></Chart>
          </div>
        </Card>
      </Grid>
      <Grid md={6} xs={12}>
        <UserCountByVoteAndPost
          totalCountByPostAndVote={totalCountByPostAndVote}
          totalUserCounts={totalUserCounts}
        />
      </Grid>
    </Grid>
  );
};
export default GenderPercentage;
