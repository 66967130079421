import React, { useEffect, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import { Box, Grid } from "@mui/material";
import "./index.css";
import AdminTabs from "../../components/AdminTabs";
import Logout from "../../assets/icons/logoutImg.svg";
import { logoutUser } from "../../actions/auth";
import { getCategoriesData } from "../../actions/categoriesData";
import { getCategoryCount, getJunkeeCount } from "../../actions/overallStats";
import {
  getQuestionVoteCountByAge,
  getQuestionVoteCountByEthnicity,
  getUserCountByVote,
  getUserRole,
  getVoteCountByTime,
} from "../../actions/userData";
const Dashboard = () => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(0);
  const { userRole, portalType } = localStorage;

  const logOut = useCallback(() => {
    dispatch(logoutUser());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getUserRole(JSON.parse(localStorage.getItem("userId"))));
    const currTime = Math.floor(Date.now() / 1000);
    const loggedTime = currTime - localStorage.getItem("loginTime");
    if (loggedTime > 1800) dispatch(logoutUser());
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      switch (activeTab) {
        case 0:
          await Promise.all([
            dispatch(getUserCountByVote(portalType)),
            dispatch(getJunkeeCount(portalType)),
            dispatch(getCategoryCount(portalType)),
            dispatch(getVoteCountByTime(portalType)),
          ]);
          break;

        case 1:
          await dispatch(getCategoriesData(portalType));
          break;
        case 3:
          dispatch(getQuestionVoteCountByAge(portalType));
          break;
        case 4:
          await dispatch(getQuestionVoteCountByEthnicity(portalType));
          break;
        default:

          break;
      }
    };

    fetchData();
  }, [activeTab, portalType, dispatch]);

  const capitalizedDashboardAdminName =
    portalType.charAt(0).toUpperCase() + portalType.slice(1);

  if (userRole == "superadmin") {
    return <Redirect to="/superadmin/dashboard" />;
  } else {
    return (
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2} style={{ marginBottom: 100 }}>
          <Grid item xs={4} md={4}>
            <p className="admin">WELCOME, ADMIN!</p>
          </Grid>
          <Grid item xs={4} md={4}></Grid>
          <Grid item xs={4} md={4}>
            <div className="logout">
              <p>LOGOUT</p>
              <img src={Logout} className="logout_Svg" onClick={logOut} />
            </div>
          </Grid>
          <Grid item xs={12} md={12}>
            <div className="list">
              <p>{capitalizedDashboardAdminName} Dashboard</p>
            </div>
          </Grid>
          <AdminTabs activeTab={activeTab} setActiveTab={setActiveTab} />
        </Grid>
      </Box>
    );
  }
};
export default Dashboard;
