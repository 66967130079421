import React from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  ListItem,
  ListItemAvatar,
} from "@mui/material";
import CountUp from "react-countup";
export const StatsCard = ({ data, showTypes, showTypes2, showTypes3 }) => {
  const dashboardAdminName = localStorage.getItem("portalType");

  const renderTopExpert = () => {
    const {
      expertHeading,
      icon,
      junkeeCounts,
      expertButtonLabel,
      handleExpertButtonClick,
    } = data;
    return (
      
      <Card sx={{ maxWidth: 310, boxShadow: "none", margin: "auto" ,}}>
        <CardContent
          style={{ backgroundColor: "#f7f6fe",height: 300 }}
        >
          <div>
            <div className="experts_heading">
             
                <h3>{expertHeading}</h3>
            </div>

            <div className="icon">
              {icon}
              <br />
              <span style={{ fontSize: 36 }}>
                <CountUp duration={0.6} end={junkeeCounts} />
              </span>
              {/* <span>{junkeeCounts}</span> */}
            </div>
          </div>
        </CardContent>

        {dashboardAdminName === "winepilot" ? null : (
          <CardActions style={{ justifyContent: "center" }}>
            <Button
              variant="contained"
              className="btn"
              onClick={handleExpertButtonClick}
              style={{ backgroundColor: "#7C10A7", borderRadius: 5 }}
            >
              {expertButtonLabel}
            </Button>
          </CardActions>
        )}
      </Card>
    );
  };

  const renderTopCategories = () => {
    const {
      heading,
      categories,
      categoryButtonLabel,
      handleCategoryButtonClick,
    } = data;

    return (
      <Card sx={{ maxWidth: 310, boxShadow: "none", margin: "auto" }}>
        <CardContent
          style={{ backgroundColor: "#f7f6fe",height: 300 }}
        >
          <div className="experts_heading">
            <h3>{heading}</h3>
          </div>
          <div sx={{ width: "100%", maxWidth: 360 }}>
            {categories?.map((category) => (
              <ListItem key={category.title}>
                <ListItemAvatar className="top_cateogory_icon">
                  {" "}
                  {category.icon}
                </ListItemAvatar>
                <p className="top_cateogory_text">{category.title}</p>
              </ListItem>
            ))}
          </div>
        </CardContent>
        {dashboardAdminName === "winepilot" ? null : (
          <CardActions style={{ justifyContent: "center" }}>
            <Button
              variant="contained"
              className="btn"
              onClick={handleCategoryButtonClick}
              style={{ backgroundColor: "#7C10A7", borderRadius: 5 }}
            >
              {categoryButtonLabel}
            </Button>
          </CardActions>
        )}
      </Card>
    );
  };

  const renderTopUsers = () => {
    const {
      heading,
      usersimage,
      usersname,
      userButtonLabel,
      handleUserButtonClick,
    } = data;
    return (
      <Card sx={{ maxWidth: 310, boxShadow: "none", margin: "auto" }}>
        <CardContent
          style={{ backgroundColor: "#f7f6fe",height: 300 }}
        >
          <div className="experts_heading">
           <></> <h3>{heading}</h3>
          </div>
          <div sx={{ width: "100%" }} className="top_user_align">
            {usersimage.map((image, index) => (
              <ListItem
                key={index}
                sx={{
                  padding: "0px",
                  margin: "0px",
                }}
              >
                <ListItemAvatar className="total_top_user">
                  {" "}
                  {image}
                </ListItemAvatar>
                <p className="total_user_top">{usersname[index]}</p>
              </ListItem>
            ))}
          </div>
        </CardContent>
        <CardActions style={{ justifyContent: "center" }}>
          <Button
            variant="contained"
            className="btn"
            onClick={handleUserButtonClick}
            style={{ backgroundColor: "#7C10A7", borderRadius: 5 }}
          >
            {userButtonLabel}
          </Button>
        </CardActions>
      </Card>
    );
  };

  return (
    <>
      {showTypes && renderTopExpert()}
      {showTypes2 && renderTopCategories()}
      {showTypes3 && renderTopUsers()}
    </>
  );
};
