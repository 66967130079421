import React from "react";
import { Grid } from "@mui/material";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import CountUp from "react-countup";

const Summary = ({ seriesNewData }) => {
  return (
    <Grid item md={6} xs={12}>
      <Card sx={{ m: 2, boxShadow: 2 }} style={{ width: "95%" }}>
        <p className="top-user-heading">Summary</p>

        <Typography sx={{ p: 1.5, m: "9px auto" }} className="stats-typography">
          <div className="average-data-count">
            {" "}
            <CountUp duration={0.8} end={seriesNewData[1]} />
          </div>
          <div className="average-data-heading"> Average Post</div>
        </Typography>
        <Typography sx={{ p: 1.5, m: "9px auto" }} className="stats-typography">
          <div className="average-data-count">
            {" "}
            <CountUp duration={0.8} end={seriesNewData[2]} />
          </div>
          <div className="average-data-heading"> Average Vote</div>
        </Typography>
        <Typography sx={{ p: 1.5, m: "9px auto" }} className="stats-typography">
          <div className="average-data-count">
            {" "}
            <CountUp duration={0.8} end={seriesNewData[0]} />
          </div>
          <div className="average-data-heading-last"> Average Comment</div>
        </Typography>
        <Card sx={{ p: 1.5 }}></Card>
      </Card>
    </Grid>
  );
};

export default Summary;
