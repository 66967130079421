import { get } from "../api/axios";
import {
  ALL_EXPERTS,
  ALL_EXPERTS_FAILURE,
  ALL_EXPERTS_SUCCESS,
  ALL_CATEGORY_FAILURE,
  ALL_CATEGORY_SUCCESS,
  ALL_USER_FAILURE,
  ALL_USER_SUCCESS,
  ALL_EXPERTS_NEW_SUCCESS,
  ALL_EXPERTS_NEW_FAILURE,
  ALL_JUNKEECOUNT_NEW_FAILURE,
  ALL_WINEPILOT_USER_SUCCESS,
  ALL_WINEPILOT_USER_FAILURE,
} from "../constants";
import {
  ALL_JUNKEE_NEW_SUCCESS,
  ALL_JUNKEECOUNT_NEW_SUCCESS,
} from "../constants/index";
import { getPortalTypeAccordingToUrl } from "../utils/helpers";

export function getExpertsCountSucess(payload) {
  return {
    type: ALL_EXPERTS_SUCCESS,
    payload,
  };
}
export function getExpertsCountFailure(payload) {
  return {
    type: ALL_EXPERTS_FAILURE,
    payload,
  };
}
export function getWinePilotUserSucess(payload) {
  return {
    type: ALL_WINEPILOT_USER_SUCCESS,
    payload,
  };
}
export function getWinePilotUserFailure(payload) {
  return {
    type: ALL_WINEPILOT_USER_FAILURE,
    payload,
  };
}
export function getJunkeeCountSucess(payload) {
  return {
    type: ALL_JUNKEECOUNT_NEW_SUCCESS,
    payload,
  };
}
export function getJunkeeCountFailure(payload) {
  return {
    type: ALL_JUNKEECOUNT_NEW_FAILURE,
    payload,
  };
}

export function getExpertsNewCountSucess(payload) {
  return {
    type: ALL_EXPERTS_NEW_SUCCESS,
    payload,
  };
}
export function getExpertsNewCountFailure(payload) {
  return {
    type: ALL_EXPERTS_NEW_FAILURE,
    payload,
  };
}
export function getCategoryCountSucess(payload) {
  return {
    type: ALL_CATEGORY_SUCCESS,
    payload,
  };
}
export function getCategoryCountFailure(payload) {
  return {
    type: ALL_CATEGORY_FAILURE,
    payload,
  };
}
export function getUserCountSuccess(payload) {
  return {
    type: ALL_USER_SUCCESS,
    payload,
  };
}
export function getUserCountFailure(payload) {
  return {
    type: ALL_USER_FAILURE,
    payload,
  };
}

export function getExpertsCount(type) {
  return async (dispatch) => {
    const response = await get(`dashboard/junkee/experts`, {
      params: { portal: type },
    });
    if (response && response.status === 200 && response.data) {
      dispatch(getExpertsCountSucess(response.data));
    } else {
      dispatch(getExpertsCountFailure("Please Try again"));
    }
  };
}
export function getWinepilotUser(type) {
  return async (dispatch) => {
    const response = await get(`dashboard/junkee/experts`, {
      params: { portal: type },
    });
    if (response && response.status === 200 && response.data) {
      dispatch(getWinePilotUserSucess(response.data));
    } else {
      dispatch(getWinePilotUserFailure("Please Try again"));
    }
  };
}

export function getNewExpertsCount(type) {
  return async (dispatch) => {
    const response = await get(`dashboard/junkee/experts`, {
      params: { portal: type },
    });

    if (response && response.status === 200 && response.data) {
      dispatch(getExpertsNewCountSucess(response.data));
    } else {
      dispatch(getExpertsNewCountFailure("Please Try again"));
    }
  };
}
export function getJunkeeCount(type) {
  return async (dispatch) => {
    const response = await get(`dashboard/junkee/experts`, {
      params: { portal: type },
    });
    if (response && response.status === 200 && response.data) {
      dispatch(getJunkeeCountSucess(response.data));
    } else {
      dispatch(getJunkeeCountFailure("Please Try again"));
    }
  };
}

export function getCategoryCount(type) {
  return async (dispatch) => {
    const response = await get(`dashboard/junkee/top-categories`, {
      params: { portal: type },
    });
    if (response && response.status === 200 && response.data) {
      dispatch(getCategoryCountSucess(response.data));
    } else {
      dispatch(getCategoryCountFailure("Please Try again"));
    }
  };
}
export function getUserCount(type) {
  return async (dispatch) => {
    const response = await get(`dashboard/junkee/top-user`, {
      params: { portal: type },
    });

    if (response && response.status === 200 && response.data) {
      dispatch(getUserCountSuccess(response.data));
    } else {
      dispatch(getUserCountFailure("Please Try again"));
    }
  };
}
