import React from "react";
import { useSelector } from "react-redux";
import { StatsCard } from "../StatsCard";
import "./index.css";

export const TopCategory = ({ showTypes2, setActiveTab }) => {
  const { portalType } = localStorage;

  const categoryCountArr =
    useSelector((state) => state?.overallStats?.topCategories) || [];

  const topCategoryByVote =
    useSelector((state) => state?.userData?.topCategoryByVote) || [];

  const handleViewAllUsers = () => {
    if (typeof setActiveTab === "function") {
      setActiveTab(1);
    }
  };

  const topCategories = {
    heading: "TOP 3 CATEGORIES",
    categories:
      portalType == "winepilot"
        ? categoryCountArr
        : categoryCountArr
            .slice(0, 3)
            .map(({ icon, title }) => ({ icon, title })),
    categoryButtonLabel: "View All Categories",
    handleCategoryButtonClick: handleViewAllUsers,
  };

  return <StatsCard data={topCategories} showTypes2={showTypes2} />;
};
