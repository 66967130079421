import React from "react";
import { Card, CardActions, CardContent } from "@mui/material";
import CountUp from "react-countup";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
} from "@mui/material";
import { useSelector } from "react-redux";
export const VoteByTimeTrend = () => {
  const voteCountByHours = useSelector(
    (state) => state.userData.voteCountByHours
  );
  const portal = localStorage.getItem("portalType");
  return (
    <Card sx={{ maxWidth: 310, boxShadow: "none", margin: "auto" }}>
      <CardContent style={{ backgroundColor: "#f7f6fe", height: 300 }}>
        <div className="timeTrend">
          <h3>VOTES BY TIME TREND</h3>
        </div>
        <TableContainer>
          <Table
            sx={{
              maxWidth: 400,
              boxShadow: "none",
              margin: "auto",
              justifyContent: "end",
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    fontWeight: 700,
                    paddingTop: 1,
                    borderBottom: "none",
                  }}
                >
                  Hours
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: 700,
                    paddingTop: 1,
                    borderBottom: "none",
                  }}
                >
                  Votes
                </TableCell>
                <TableCell
                  style={{
                    fontWeight: 700,
                    paddingTop: 1,
                    borderBottom: "none",
                  }}
                >
                  Percentage
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {voteCountByHours.length > 0 &&
                voteCountByHours.map((value, id) => (
                  <TableRow key={id}>
                    <TableCell style={{ borderBottom: "none" }}>
                      {value.hours}
                    </TableCell>
                    <TableCell style={{ borderBottom: "none" }}>
                      {typeof value.count === "number" ? (
                        <CountUp duration={0.6} end={value.count} />
                      ) : (
                        value.count
                      )}
                    </TableCell>
                    <TableCell style={{ borderBottom: "none" }}>
                      {value.percentage}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>

    
    </Card>
  );
};
