import { combineReducers } from "redux";
import { auth } from "./auth.js";
import { overallStats } from "./overallStats";
import { categoriesData } from "./categoriesData";
import { userData } from "./userData";
import { totalUserReport } from "./totalUserReport";
import { anonymousVoteHandler } from "./anonymousVoteHandler";
export default combineReducers({
  auth,
  overallStats,
  categoriesData,
  userData,
  totalUserReport,
  anonymousVoteHandler,
});
