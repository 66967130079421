import {
  All_VOTE_AND_POST_USERS_SUCCESS,
  TOTAL_USER_REPORT_SUCCESS,
} from "../constants";
import {
  TOTAL_USER_POSTS_REPORT_SUCCESS,
  TOTAL_USER_POSTS_VOTES_DETAILS_DATE_SUCCESS,
  TOTAL_NEW_REGISTRATION_SUCCESS,
  GENDER_COUNT_SUCCESS,
} from "../constants/index";

export function totalUserReport(
  state = {
    isFetching: false,
    allUserReport: {},
    userPostsReport: {},
    userPostsAndVoteReport: {},
    genderData: {},
    newRegistration: {},
    postAndVoteByUser: [],
    errorMessage: "",
  },
  action
) {
  switch (action.type) {
    case TOTAL_USER_REPORT_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        allUserReport: action.payload,
        errorMessage: "",
      });
    case TOTAL_USER_POSTS_REPORT_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        userPostsReport: action.payload,
        errorMessage: "",
      });

    case TOTAL_USER_POSTS_VOTES_DETAILS_DATE_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        userPostsAndVoteReport: action.payload,
        errorMessage: "",
      });

    case TOTAL_NEW_REGISTRATION_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        newRegistration: action.payload,
        errorMessage: "",
      });

    case GENDER_COUNT_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        genderData: action.payload,
        errorMessage: "",
      });
    case All_VOTE_AND_POST_USERS_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        postAndVoteByUser: action.payload,
        errorMessage: "",
      });

    default:
      return state;
  }
}
