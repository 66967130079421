import React from "react";
import "./index.css";

const Loading = () => {
  return (
    <>
      <div className="loader-container">
        <div className="radial-progress-bar">
          <div className="overlay"></div>
        </div>
        <div className="spinner-text">Loading...</div>
      </div>
    </>
  );
};

export default Loading;
