import React from "react";
import Chart from "react-apexcharts";
import { Grid } from "@mui/material";
import "./index.css";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import "./index.css";
import Card from "@mui/material/Card";
import Summary from "./Summary/index";

import { getUserPostsReport } from "../../../actions/totalUserReport";
import { getExpertsCount, getNewExpertsCount, getWinepilotUser } from "../../../actions/overallStats";
const PortalTypeAndStats = () => {
  const dispatch = useDispatch();
  const userPostsReports = useSelector(
    (state) => state.totalUserReport.userPostsReport
  );

  const junkeeCounts = useSelector((state) => state.overallStats.expertCount);
  const wizerCounts = useSelector((state) => state.overallStats.newexpertCount);
  const winepilotCount = useSelector((state) => state.overallStats.winePilotCount);
  
  React.useEffect(() => {
    dispatch(getUserPostsReport(""));
    dispatch(getExpertsCount("junkee"));
    dispatch(getNewExpertsCount("wizer"));
    dispatch(getWinepilotUser ("winepilot"))

  }, []);

  const junkeeCountSeries = [];

  if (
    junkeeCounts != "undefined" &&
    junkeeCounts != [] &&
    wizerCounts != "undefined" &&
    wizerCounts != [] &&  winepilotCount != "undefined" &&
    winepilotCount != []
 
  ) {
    junkeeCountSeries.push(junkeeCounts[0].totalexper);
    junkeeCountSeries.push(wizerCounts[0].totalexper);
    junkeeCountSeries.push(winepilotCount[0].totalexper);
  }
 
  const wizerCountSeries = [];

  if (wizerCounts != "undefined" && wizerCounts != []) {
    wizerCountSeries.push(wizerCounts[0].totalexper);
  }
  const totalJunkeeWizerCount = [
    { data: junkeeCountSeries, name: "Total User" },
  ];

  const reportValue = Object.values(userPostsReports);
  const seriesData = [];

  if (reportValue.length > 0) {
    seriesData.push(reportValue[0].averagepost);
    seriesData.push(reportValue[0].averagecomment);
    seriesData.push(reportValue[0].averagevote);
  }
  const userPostsAndVoteReports = useSelector(
    (state) => state.totalUserReport.userPostsAndVoteReport
  );

  const userPostAndVoteByDate = userPostsAndVoteReports.result;

  const votePostByDate = [];

  if (userPostAndVoteByDate != undefined) {
    votePostByDate.push(userPostAndVoteByDate.numberofPosts);
    votePostByDate.push(userPostAndVoteByDate.numberofVotes);
    votePostByDate.push(userPostAndVoteByDate.numberofComments);
  }

  const reportValues = Object.values(userPostsReports);

  const seriesNewData = [];

  if (reportValues.length > 0) {
    seriesNewData.push(reportValues[0].averagecomment);
    seriesNewData.push(reportValues[0].averagepost);
    seriesNewData.push(reportValues[0].averagevote);
  }

  const Options = {
    labels: ["Junkee", "Wizer", "Winepilot"],
    plotOptions: {
      bar: {
        barHeight: "100%",
        distributed: true,
        dataLabels: {
          position: "center",
        },
      },
    },
    colors: ["#3522E6", "#800080"],
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
          <Card sx={{ m: 2, boxShadow: 2 }} style={{ width: "95%" }}>
            <p className="top-user-heading">Portal type</p>
            <Chart
              type="bar"
              series={totalJunkeeWizerCount}
              options={Options}
            ></Chart>
          </Card>
        </Grid>

        <Summary seriesNewData={seriesNewData} />
      </Grid>
    </>
  );
};

export default PortalTypeAndStats;
